import * as newE from '../new-table/entities';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import InputField from './InputField';
import SelectInput, { SelectDataType } from './SelectInput';
import { FileUploader } from 'react-drag-drop-files';
import TrazabilidadMultiForm from './TrazabilidadMultiForm';
import { Alert } from '@mui/material';
import { getUncatchEndpointData } from '../utils/request';
import { TipoActivoValues, TipoCobro, TipoUsuarioValues } from '../types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/es';
import { AgregarCobro } from './SecondaryButton';
import { AuthState } from '../reducers/types';
import { connect } from 'react-redux';
import { AppState } from '../store';
import AddIcon from '@mui/icons-material/Add';
import ModalComponent from '../new-table/components/ModalComponent';

const TrazabilidadForm: FunctionComponent<{
  setIsLoadingOutward: Dispatch<SetStateAction<boolean>>;
  isLoadingOutward: boolean;
  tipoCobro: TipoCobro;
  handleSelectedData: (data: {
    cdOrigen: string;
    cdDestino: string;
    eett: string;
    oca: string;
    patente: string;
    guia: string;
    dateOca: moment.Moment | null;
    detalle: string;
    causal: string;
    files: File[] | null;
    skuData: Array<{
      sku: string;
      cantidad: string;
    }>;
  }) => void;
}> = ({ setIsLoadingOutward, isLoadingOutward, tipoCobro, handleSelectedData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [oca, setOca] = useState<string>('');
  const [patente, setPatente] = useState<string>('');
  const [guia, setGuia] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [detalle, setDetalle] = useState<string>('');
  const [cdOrigen, setCdOrigen] = useState<Array<SelectDataType>>([]);
  const [cdOrigenOptions, setCdOrigenOptions] = useState<Array<SelectDataType>>([]);
  const [selectedCdOrigen, setSelectedCdOrigen] = useState<SelectDataType | null>(null);
  const [causales, setCausales] = useState<Array<SelectDataType>>([]);
  const [selectedCausal, setSelectedCausal] = useState<SelectDataType | null>(null);
  const [cdDestino, setCdDestino] = useState<Array<SelectDataType>>([]);
  const [cdDestinoOptions, setCdDestinoOptions] = useState<Array<SelectDataType>>([]);
  const [selectedCdDestino, setSelectedCdDestino] = useState<SelectDataType | null>(null);
  const [eett, setEett] = useState<Array<SelectDataType>>([]);
  const [selectedEett, setSelectedEett] = useState<SelectDataType | null>(null);
  const [sku, setSku] = useState<
    Array<{
      id: string;
      name: string;
      description: string;
    }>
  >([]);
  const [files, setFiles] = useState<File[] | null>(null);
  const [error, setError] = useState<string>('');
  const [formData, setFormData] = useState<{
    cdOrigen: string;
    cdDestino: string;
    eett: string;
    oca: string;
    patente: string;
    guia: string;
    dateOca: moment.Moment | null;
    detalle: string;
    causal: string;
    files: File[] | null;
    skuData: Array<{
      sku: string;
      cantidad: string;
    }>;
  }>({
    cdOrigen: '',
    cdDestino: '',
    eett: '',
    dateOca: null,
    oca: '',
    patente: '',
    guia: '',
    detalle: '',
    causal: '',
    files: null,
    skuData: []
  });

  useEffect(() => setIsLoadingOutward(loading), [setIsLoadingOutward, loading]);
  useEffect(() => setLoading(isLoadingOutward), [isLoadingOutward]);
  useEffect(() => handleSelectedData(formData), [handleSelectedData, formData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resAllCd = await getUncatchEndpointData({ endpoint: 'centroInventario/zonas/all' });
      if (resAllCd && Array.isArray(resAllCd)) {
        setCdOrigen(
          resAllCd.map((row) => ({
            value: row?.nombreCentro,
            name: row?.nombreCentro
          }))
        );
      }
      const resUserCd = await getUncatchEndpointData({ endpoint: 'centroInventario/zonas' });
      if (resUserCd && Array.isArray(resUserCd)) {
        setCdDestino(
          resUserCd.map((row) => ({
            value: row?.nombreCentro,
            name: row?.nombreCentro
          }))
        );
      }
      const resEett = await getUncatchEndpointData({
        endpoint: 'usuarioTransportista/transportistas',
        query: {
          activo: true
        }
      });
      if (resEett && Array.isArray(resEett)) {
        setEett(resEett.map((row) => ({ value: row?._id, name: row?.nombreTransportista })));
      }
      const resCausales = await getUncatchEndpointData({
        endpoint: 'tipoCausal'
      });
      if (resCausales && Array.isArray(resCausales)) {
        setCausales(resCausales.map((row) => ({ value: row?._id, name: row?.nombreCausal })));
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (selectedCdDestino) {
        const resSku = await getUncatchEndpointData({
          endpoint: 'sku/getByCentro',
          query: {
            nombreCentro: selectedCdDestino.value, // column 'nombreCentro' from 'centros_inventario' table
            idTipoActivo: TipoActivoValues.productoTerminado
          }
        });
        if (resSku && Array.isArray(resSku)) {
          setSku(
            resSku.map((row) => ({
              id: row?.id,
              name: `${row?.material} (Centro: ${row?.centro})`,
              description: row?.textoBreveMaterial
            }))
          );
        }
      } else {
        setSku([]);
      }
      setLoading(false);
    })();
  }, [selectedCdDestino]);

  //NOTE: hooks that prevent the same source and destination CD from being selectable
  useEffect(() => {
    if (selectedCdDestino) {
      setCdOrigenOptions(cdOrigen.filter((obj) => obj.value !== selectedCdDestino.value));
      if (selectedCdDestino.value === selectedCdOrigen?.value) {
        setSelectedCdOrigen(null);
        setFormData((prev) => ({ ...prev, cdOrigen: '' }));
      }
    } else {
      setCdOrigenOptions(cdOrigen);
    }
  }, [cdOrigen, selectedCdDestino]);

  useEffect(() => {
    if (selectedCdOrigen) {
      setCdDestinoOptions(cdDestino.filter((obj) => obj.value !== selectedCdOrigen.value));
      if (selectedCdOrigen.value === selectedCdDestino?.value) {
        setSelectedCdDestino(null);
        setFormData((prev) => ({ ...prev, cdDestino: '' }));
      }
    } else {
      setCdDestinoOptions(cdDestino);
    }
  }, [cdDestino, selectedCdOrigen]);

  const handleChangeFiles = (newFiles: File[] | null) => {
    if (!newFiles) {
      setError('');
      return;
    }

    if ((files && files.length + newFiles.length > 5) || newFiles.length > 5) {
      setError('Solo puedes subir un máximo de 5 archivos.');
      return;
    }
    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        setError(`El archivo ${file.name} excede el tamaño máximo de 5 MB.`);
        return;
      }
    }

    setFiles((prevFiles) => {
      if (!prevFiles) return [...newFiles];
      return [...prevFiles, ...newFiles];
    });
    setError('');
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      if (prevFiles) {
        const res = prevFiles.filter((_, i) => i !== index);
        if (res.length === 0) return null;
        return res;
      }
      return null;
    });
  };

  useEffect(() => setFormData((prev) => ({ ...prev, files })), [files]);

  return (
    <div className='trazabilidad-form-container'>
      <SelectInput
        label='Selecciona CD Origen'
        value={selectedCdOrigen}
        onChange={(_, newValue) => {
          if (selectedCdDestino && selectedCdDestino?.value === newValue?.value) {
            setSelectedCdOrigen(null);
            setFormData((prev) => ({ ...prev, cdOrigen: '' }));
            return;
          }
          setSelectedCdOrigen(newValue);
          setFormData((prev) => ({ ...prev, cdOrigen: (newValue?.value || '').toString() }));
        }}
        options={cdOrigenOptions}
        disabled={loading}
        required
      />

      <SelectInput
        label='Selecciona CD Destino'
        value={selectedCdDestino}
        onChange={(_, newValue) => {
          if (selectedCdOrigen && selectedCdOrigen?.value === newValue?.value) {
            setSelectedCdDestino(null);
            setFormData((prev) => ({ ...prev, cdDestino: '' }));
            return;
          }
          setSelectedCdDestino(newValue);
          setFormData((prev) => ({ ...prev, cdDestino: (newValue?.value || '').toString() }));
        }}
        options={cdDestinoOptions}
        disabled={loading}
        required
      />

      <SelectInput
        label='Selecciona una EETT'
        value={selectedEett}
        onChange={(_, newValue) => {
          setSelectedEett(newValue);
          setFormData((prev) => ({ ...prev, eett: (newValue?.value || '').toString() }));
        }}
        options={eett}
        disabled={loading}
        required
      />

      <InputField
        label='OCA'
        value={oca}
        type='text'
        handleInputChange={(e) => {
          setOca((e.target.value || '').toUpperCase());
          setFormData((prev) => ({ ...prev, oca: (e.target.value || '').toUpperCase() }));
        }}
        maxLength={14}
        disabled={loading}
        fullWidth
        required
      />

      <InputField
        label='Patente'
        value={patente}
        type='text'
        handleInputChange={(e) => {
          const newValue = e.target.value;
          if (newValue === '' || /^[a-zA-Z0-9]+$/.test(newValue)) {
            setPatente((newValue || '').toUpperCase());
            setFormData((prev) => ({ ...prev, patente: (newValue || '').toUpperCase() }));
          }
        }}
        maxLength={6}
        disabled={loading}
        error={patente && patente.length !== 6 ? 'La patente debe tener 6 caracteres' : undefined}
        fullWidth
        required
      />

      <InputField
        label='Guia'
        value={guia}
        type='text'
        handleInputChange={(e) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            // integers only
            setGuia(newValue);
            setFormData((prev) => ({ ...prev, guia: newValue }));
          }
        }}
        maxLength={14}
        disabled={loading}
        fullWidth
        required
      />

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          label='Fecha OCA'
          views={['year', 'month', 'day']}
          openTo='day'
          value={selectedDate}
          disabled={loading}
          onChange={(date) => {
            setSelectedDate(date);
            setFormData((prev) => ({ ...prev, dateOca: date }));
          }}
          slotProps={{
            field: { clearable: true, format: 'DD/MM/YYYY' }
          }}
          minDate={moment(new Date()).subtract(1, 'month')}
          maxDate={moment(new Date())}
        />
      </LocalizationProvider>

      <InputField
        label='Observación'
        value={detalle}
        type='text'
        handleInputChange={(e) => {
          setDetalle(e.target.value);
          setFormData((prev) => ({ ...prev, detalle: e.target.value }));
        }}
        fullWidth
        disabled={loading}
        required
      />

      {tipoCobro === TipoCobro.merma && (
        <SelectInput
          label='Selecciona Causal'
          value={selectedCausal}
          onChange={(_, newValue) => {
            setSelectedCausal(newValue);
            setFormData((prev) => ({ ...prev, causal: (newValue?.value || '').toString() }));
          }}
          options={causales}
          disabled={loading}
          required
        />
      )}

      <div className='trazabilidad-file-uploader-container'>
        <FileUploader
          classes='trazabilidad-file-uploader'
          handleChange={handleChangeFiles}
          name='file'
          label='Cargue o arrastre los PDF aquí'
          hoverTitle='Soltar aquí'
          types={['pdf']}
          fileOrFiles={files}
          disabled={loading}
          multiple
          required
        />
        {error && <Alert severity='warning'>{error}</Alert>}
        {files && files.length > 0 && (
          <div className='trazabilidad-file-uploader-file-list-container'>
            <h3 className='trazabilidad-file-uploader-file-list-title'>Archivos cargados:</h3>

            {files.map((file, idx) => (
              <p key={idx} className='trazabilidad-file-uploader-file-list-item'>
                <span
                  className='trazabilidad-file-uploader-file-list-item-remove'
                  onClick={() => handleRemoveFile(idx)}>
                  X
                </span>{' '}
                {file.name}
              </p>
            ))}
          </div>
        )}
      </div>

      <TrazabilidadMultiForm
        sku={sku}
        isLoading={loading}
        handleChangeSelectedSku={(selectedSku) =>
          setFormData((prev) => ({ ...prev, skuData: selectedSku }))
        }
      />
    </div>
  );
};

const TrazabilidadModalForm: FunctionComponent<{ auth: AuthState; tipoCobro: TipoCobro }> = ({
  auth,
  tipoCobro
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };
  const [data, setData] = useState<{
    cdOrigen: string;
    cdDestino: string;
    eett: string;
    oca: string;
    patente: string;
    guia: string;
    dateOca: moment.Moment | null;
    detalle: string;
    causal: string;
    files: File[] | null;
    skuData: Array<{
      sku: string;
      cantidad: string;
    }>;
  }>({
    cdOrigen: '',
    cdDestino: '',
    eett: '',
    dateOca: null,
    oca: '',
    patente: '',
    guia: '',
    detalle: '',
    causal: '',
    files: null,
    skuData: []
  });

  const webEntity = useMemo(
    () => (tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteIngresada : newE.OcaMermaIngresada),
    [tipoCobro]
  );

  const allowToAdd = useMemo(
    () =>
      auth.user &&
      [TipoUsuarioValues.Admin, TipoUsuarioValues.AdminCobros, TipoUsuarioValues.Operador].includes(
        auth.user?.nombreTipoUsuario
      ),
    [auth.user]
  );

  return !allowToAdd ? (
    <></>
  ) : (
    <ModalComponent
      btnIcon={<AddIcon fontSize='large' />}
      btnLabel='Agregar'
      modalTitle={`Agregar ${tipoCobro}`}
      body={
        <TrazabilidadForm
          setIsLoadingOutward={setIsLoading}
          isLoadingOutward={isLoading}
          handleSelectedData={(handleData) => setData(handleData)}
          tipoCobro={tipoCobro}
        />
      }
      actionBtn={
        <AgregarCobro
          tipoCobro={tipoCobro}
          data={data}
          disabled={isLoading}
          toggleModal={() => handleToggleModal(false)}
          setLoadingOutward={setIsLoading}
          webEntity={webEntity}
        />
      }
      btnVariant='contained'
      btnColor='success'
      customStyles={{ modalBtn: { padding: '12px' } }}
      toggleModal={handleToggleModal}
      isModalOpen={open}
    />
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(TrazabilidadModalForm);
