import { ConteoCriticidadLog } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const ConteoCriticidadLogEntity: WebEntity<ConteoCriticidadLog> = {
  name: 'conteoCriticidadLog',
  endpoint: 'inventarioCriticidad/log',
  referenceColumn: 'id',

  tableColumns: [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'ID Conteo Criticidad',
      id: 'idConteoCriticidad',
      selector: (row) => row.idConteoCriticidad,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Comentario',
      id: 'comentarioLog',
      selector: (row) => row.comentarioLog,
      columnType: 'text',
      wrap: true
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem'
          }
        }
      ]*/
    },
    {
      name: 'Mantenedor Incompleto',
      id: 'mantenedorIncompleto',
      selector: (row) => row.mantenedorIncompleto,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Cantidad Contada',
      id: 'cantidadContada',
      selector: (row) => row.cantidadContada,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Bodega',
      id: 'bodega',
      selector: (row) => row.bodega,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Centro',
      id: 'idCentro',
      selector: (row) => row.idCentro,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Almacen',
      id: 'almacen',
      selector: (row) => row.almacen,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Material',
      id: 'material',
      selector: (row) => row.material,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Lote',
      id: 'lote',
      selector: (row) => row.lote,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Registro',
      id: 'fechaRegistro',
      selector: (row) => row.fechaRegistro,
      columnType: 'dateUTC',
      format: ({ fechaRegistro: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Produccion ',
      id: 'fechaProduccion',
      selector: (row) => row.fechaProduccion,
      columnType: 'dateUTC',
      format: ({ fechaProduccion: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Vencimiento',
      id: 'fechaVencimiento',
      selector: (row) => row.fechaVencimiento,
      columnType: 'dateUTC',
      format: ({ fechaVencimiento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad Cantidad Contada',
      id: 'unidadCantidadContada',
      selector: (row) => row.unidadCantidadContada,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Tipo Stock',
      id: 'tipoStock',
      selector: (row) => row.tipoStock,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Pedido Cliente',
      id: 'pedidoCliente',
      selector: (row) => row.pedidoCliente,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Numero Hu',
      id: 'numeroHu',
      selector: (row) => row.numeroHu,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'ID Documento',
      id: 'idDocumento',
      selector: (row) => row.idDocumento,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Usuario',
      id: 'idUsuario',
      selector: (row) => row.idUsuario,
      columnType: 'text',
      sortable: true
    }
  ],

  filterFields: [
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text
    },
    {
      name: 'Mantenedor Incompleto',
      selector: 'mantenedorIncompleto',
      type: FieldTypes.Reference,
      reference: {
        name: 'mantenedorIncompleto',
        select: 'value',
        show: 'option',
        data: [
          { option: 'Dias Venta', value: 'Dias Venta' },
          { option: 'Perfil Envejecimiento', value: 'Perfil Envejecimiento' },
          { option: 'SKU Factor', value: 'SKU Factor' }
        ]
      }
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Lote',
      selector: 'lote',
      type: FieldTypes.Text
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      timezone: 'America/Santiago'
    },
    {
      name: 'Fecha Produccion ',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      timezone: 'America/Santiago'
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago'
    },
    {
      name: 'Comentario',
      selector: 'comentarioLog',
      type: FieldTypes.Text
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      type: FieldTypes.Text
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      type: FieldTypes.Text
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      type: FieldTypes.Text
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      type: FieldTypes.Text
    },
    {
      name: 'Usuario',
      selector: 'idUsuario',
      type: FieldTypes.Text
    }
  ]
};

export default ConteoCriticidadLogEntity;
