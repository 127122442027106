import React from 'react';
import { Dashboard } from '../../types';
import { CustomChip, DownloadFile } from '../utils';
import { WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../../utils/utils';
import { Box, Stack } from '@mui/material';

const DashboardEntity: WebEntity<Dashboard> = {
  name: 'cobroDashboard',
  endpoint: 'cobros/dashboard',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'OCA',
      accessorKey: 'oca',
      columnType: 'text'
    },
    {
      header: 'Estado',
      accessorKey: 'estadoOca',
      columnType: 'text',
      size: 120,
      AggregatedCell: ({ row }) => <CustomChip estadoOca={row.original?.estadoOca} />
    },
    {
      header: 'Fecha OCA',
      accessorKey: 'fechaOca',
      columnType: 'dateUTC',
      size: 180,
      AggregatedCell: ({ row }) => dateFormatFn(row.original.fechaOca, 'DD/MM/YYYY', true)
    },
    {
      header: 'Transporte',
      accessorKey: 'nombreTransportista',
      columnType: 'text',
      size: 160,
      AggregatedCell: ({ row }) => row.original?.nombreTransportista
    },
    {
      header: 'SKU',
      accessorKey: 'material',
      columnType: 'text',
      size: 120
    },
    {
      header: 'Descripcion SKU',
      accessorKey: 'textoBreveMaterial',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Precio costo unitario',
      accessorKey: 'precioUnitario',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) => `$ ${formatStringNumber(row.original.precio)}`,
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                fontWeight: 'bold'
              }}>
              {sum && `$ ${formatStringNumber(sum.toString())}`}
            </Box>
          </div>
        );
      }
    },
    {
      header: 'Cantidad de cajas',
      accessorKey: 'cantidadCajas',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          {formatStringNumber(row?.original.cantidadCajas, 0, 0)}
        </div>
      ),
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                fontWeight: 'bold'
              }}>
              {sum && formatStringNumber(sum?.toString(), 0, 0)}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Dashboard) =>
            obj.cantidadCajas ? acc + Number(obj.cantidadCajas) : acc,
          0
        );
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              Total: {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Precio costo subtotal',
      accessorKey: 'precio',
      columnType: 'numeric',
      size: 200,
      Cell: ({ row }) =>
        !isNaN(Number(row.original.precio)) && !isNaN(Number(row.original.cantidadCajas))
          ? `$ ${formatStringNumber(
              (Number(row.original.precio) * Number(row.original.cantidadCajas)).toString()
            )}`
          : '',
      AggregatedCell: ({ row }) => {
        const sum = row.subRows?.reduce(
          (acc, subRow: any) =>
            !isNaN(Number(subRow.original.precio)) && !isNaN(Number(subRow.original.cantidadCajas))
              ? acc + Number(subRow.original.precio) * Number(subRow.original.cantidadCajas)
              : 0,
          0
        );

        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                fontWeight: 'bold'
              }}>
              {sum && `$ ${formatStringNumber(sum.toString())}`}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: Dashboard) =>
            !isNaN(Number(obj.precio)) && !isNaN(Number(obj.cantidadCajas))
              ? acc + Number(obj.precio) * Number(obj.cantidadCajas)
              : 0,
          0
        );
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              Total: $ {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Detalle del cobro',
      accessorKey: 'detalle',
      columnType: 'text',
      size: 180,
      AggregatedCell: ({ row }) => row.original?.detalle
    },
    {
      header: 'Evidencia',
      accessorKey: 'evidencia',
      columnType: 'text',
      size: 160,
      omitExport: true,
      AggregatedCell: ({ row }) => {
        return row.original.evidencia ? (
          <DownloadFile
            fileName={row.original.evidencia}
            fileType='application/zip'
            buttonName='Evidencia OCA'
          />
        ) : (
          ''
        );
      }
    },
    {
      header: 'ID Carta Cobranza',
      accessorKey: 'cobroId',
      columnType: 'text',
      size: 180,
      AggregatedCell: ({ row }) => row.original?.cobroId
    }
  ]
};

export default DashboardEntity;
