import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { TipoUsuarioValues } from '../types';

interface IProps {
  auth: AuthState;
}

const LandingPage: FunctionComponent<IProps> = ({ auth }) => {
  const { isAuthenticated, user } = auth;
  const allowedUserTypesInventario = [
    TipoUsuarioValues.Admin as string,
    TipoUsuarioValues.Operador as string,
    TipoUsuarioValues.Visualizador as string
  ];

  const allowedUserTypesCobros = [
    TipoUsuarioValues.AdminCobros as string,
    TipoUsuarioValues.Transportista as string
  ];

  if (isAuthenticated) {
    if (allowedUserTypesInventario.includes(user!.nombreTipoUsuario)) {
      return <Redirect to='/conteo-criticidad' />;
    }
    if (allowedUserTypesCobros.includes(user!.nombreTipoUsuario)) {
      return <Redirect to='/cobros' />;
    }
  }

  return null;
};

export default connect(({ auth }: AppState) => ({ auth }))(LandingPage);
