import { SkuFactor } from '../types';
import { FieldTypes, WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const SKUFactorEntity: WebEntity<SkuFactor> = {
  name: 'skuFactor',
  endpoint: 'skuFactor',
  referenceColumn: 'id',

  /*
   * material
   * factorTccu
   * grupoArticulo
   * descripcion
   **/
  tableColumns: [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.id,
      columnType: 'text'
    },
    {
      name: 'Material',
      id: 'material',
      selector: (row) => row.material,
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Factor Tccu',
      id: 'factorTccu',
      selector: (row) => row.factorTccu,
      columnType: 'numeric',
      format: ({ factorTccu }) => formatStringNumber(factorTccu),
      wrap: true
    },
    {
      name: 'Grupo Articulo',
      id: 'grupoArticulo',
      selector: (row) => row.grupoArticulo,
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Descripcion',
      id: 'descripcion',
      selector: (row) => row.descripcion,
      columnType: 'text',
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem'
          }
        }
      ],*/
      wrap: true
    }
  ],

  fields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Factor Tccu',
      selector: 'factorTccu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulo',
      selector: 'grupoArticulo',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'descripcion',
      selector: 'descripcion',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Factor Tccu',
      selector: 'factorTccu',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulo',
      selector: 'grupoArticulo',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'descripcion',
      selector: 'descripcion',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default SKUFactorEntity;
