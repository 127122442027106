import { Comentario, IdTipoDocumentoValues, NameConteoValues } from '../types';
import { FieldTypes, WebEntity } from './types';

const ComentarioEntity: WebEntity<Comentario> = {
  name: 'comentario',
  endpoint: 'comentario',
  referenceColumn: 'comentario',

  tableColumns: [
    {
      name: 'ID',
      id: 'idComentario',
      selector: (row) => row.idComentario,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Tipo Conteo',
      id: 'descripcionTipoDocumento',
      selector: (row) => row.descripcionTipoDocumento,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Comentario',
      id: 'comentario',
      selector: (row) => row.comentario,
      columnType: 'text',
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Tipo Conteo',
      selector: 'idTipoDocumento',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'idTipoDocumento',
        select: 'value',
        show: 'option',
        data: [
          {
            option: NameConteoValues.conteoCriticidad,
            value: IdTipoDocumentoValues.idConteoCriticidad
          },
          { option: NameConteoValues.conteoEnvases, value: IdTipoDocumentoValues.idConteoEnvase },
          { option: NameConteoValues.conteoPallet, value: IdTipoDocumentoValues.idConteoPallet }
        ]
      }
    },
    {
      name: 'Comentario',
      selector: 'comentario',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Comentario',
      selector: 'comentario',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default ComentarioEntity;
