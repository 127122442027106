import * as newE from '../new-table/entities';
import React, { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState, EntitiesState } from '../reducers/types';
import BackButton from '../new-table/components/BackButton';
import { CobroCRUD, OcaCRUD } from '../new-table/components/GeneratedComponents';
import { EstadoOCA, TipoCobro, TipoUsuarioValues } from '../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Box, Tab } from '@mui/material';
import { CustomTabLabel } from '../new-table/utils';
import { getUncatchEndpointData } from '../utils/request';

const TrazabilidadMerma: FunctionComponent<{ auth: AuthState; entities: EntitiesState }> = ({
  auth,
  entities
}) => {
  const [value, setValue] = useState<string>('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const isTransportista = useMemo(
    () => auth.user?.nombreTipoUsuario === TipoUsuarioValues.Transportista,
    [auth.user?.nombreTipoUsuario]
  );

  const isOperador = useMemo(
    () => auth.user?.nombreTipoUsuario === TipoUsuarioValues.Operador,
    [auth.user?.nombreTipoUsuario]
  );

  const isAdminCobros = useMemo(
    () => auth.user?.nombreTipoUsuario === TipoUsuarioValues.AdminCobros,
    [auth.user?.nombreTipoUsuario]
  );

  const [quantities, setQuantities] = useState<{
    [EstadoOCA.ingresada]: number;
    [EstadoOCA.objetadaOrigen]: number;
    [EstadoOCA.objetadaTransportista]: number;
    [EstadoOCA.cobrable]: number;
    [EstadoOCA.enDiscusion]: number;
    [EstadoOCA.cobrada]: number;
    [EstadoOCA.cerrada]: number;
    [EstadoOCA.expirada]: number;
  }>({
    [EstadoOCA.ingresada]: 0,
    [EstadoOCA.objetadaOrigen]: 0,
    [EstadoOCA.objetadaTransportista]: 0,
    [EstadoOCA.cobrable]: 0,
    [EstadoOCA.enDiscusion]: 0,
    [EstadoOCA.cobrada]: 0,
    [EstadoOCA.cerrada]: 0,
    [EstadoOCA.expirada]: 0
  });

  useEffect(() => {
    (async () => {
      const res: Array<{ estadoOca: string; quantity: string }> = await getUncatchEndpointData({
        endpoint: 'cobros/ocas/quantity',
        query: {
          tipoCobro: TipoCobro.merma
        }
      });

      const resCobro: Array<{ cobroId: string }> = await getUncatchEndpointData({
        endpoint: 'cobros',
        query: {
          tipoCobro: TipoCobro.merma
        }
      });
      if (res && resCobro) {
        const ingresadaQuantity = res.find(
          (row) => row.estadoOca === EstadoOCA.ingresada
        )?.quantity;
        const objetadaOrigenQuantity = res.find(
          (row) => row.estadoOca === EstadoOCA.objetadaOrigen
        )?.quantity;
        const objetadaTransportistaQuantity = res.find(
          (row) => row.estadoOca === EstadoOCA.objetadaTransportista
        )?.quantity;
        const cobrableQuantity = res.find((row) => row.estadoOca === EstadoOCA.cobrable)?.quantity;
        const enDiscusionQuantity = res.find(
          (row) => row.estadoOca === EstadoOCA.enDiscusion
        )?.quantity;
        const cobradaQuantity = new Set(resCobro.map((row) => row.cobroId)).size;
        const cerradaQuantity = res.find((row) => row.estadoOca === EstadoOCA.cerrada)?.quantity;
        const expiradaQuantity = res.find((row) => row.estadoOca === EstadoOCA.expirada)?.quantity;

        setQuantities({
          [EstadoOCA.ingresada]: !isNaN(Number(ingresadaQuantity)) ? Number(ingresadaQuantity) : 0,
          [EstadoOCA.objetadaOrigen]: !isNaN(Number(objetadaOrigenQuantity))
            ? Number(objetadaOrigenQuantity)
            : 0,
          [EstadoOCA.objetadaTransportista]: !isNaN(Number(objetadaTransportistaQuantity))
            ? Number(objetadaTransportistaQuantity)
            : 0,
          [EstadoOCA.cobrable]: !isNaN(Number(cobrableQuantity)) ? Number(cobrableQuantity) : 0,
          [EstadoOCA.enDiscusion]: !isNaN(Number(enDiscusionQuantity))
            ? Number(enDiscusionQuantity)
            : 0,
          [EstadoOCA.cobrada]: !isNaN(Number(cobradaQuantity)) ? Number(cobradaQuantity) : 0,
          [EstadoOCA.cerrada]: !isNaN(Number(cerradaQuantity)) ? Number(cerradaQuantity) : 0,
          [EstadoOCA.expirada]: !isNaN(Number(expiradaQuantity)) ? Number(expiradaQuantity) : 0
        });
      }
    })();
  }, [entities, value]);

  const CRUDingresada = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.ingresada,
        newE.OcaMermaIngresada,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDobjetadaOrigen = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.objetadaOrigen,
        newE.OcaMermaObjetadaOrigen,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDobjetadaTransportista = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.objetadaTransportista,
        newE.OcaMermaObjetadaTransportista,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDcobrable = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.cobrable,
        newE.OcaMermaCobrable,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDenDiscusion = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.enDiscusion,
        newE.OcaMermaEnDiscusion,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDcobrada = useMemo(() => CobroCRUD(TipoCobro.merma), []);

  const CRUDcerrada = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.cerrada,
        newE.OcaMermaCerrada,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  const CRUDexpirada = useMemo(
    () =>
      OcaCRUD(
        TipoCobro.merma,
        EstadoOCA.expirada,
        newE.OcaMermaExpirada,
        auth.user?.nombreTipoUsuario
      ),
    [auth.user?.nombreTipoUsuario]
  );

  return (
    <div className='trazabilidad-container'>
      <div className='trazabilidad-title-container'>
        <BackButton route='/cobros' />
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab
              label={
                <CustomTabLabel label='Ingresada' quantity={quantities[EstadoOCA.ingresada]} />
              }
              value='1'
            />
            <Tab
              label={
                <CustomTabLabel
                  label='Objetada Origen'
                  quantity={quantities[EstadoOCA.objetadaOrigen]}
                />
              }
              value='2'
            />
            <Tab
              label={
                <CustomTabLabel
                  label='Objetada Transportista'
                  quantity={quantities[EstadoOCA.objetadaTransportista]}
                />
              }
              value='3'
            />
            <Tab
              label={
                <CustomTabLabel label='En discusion' quantity={quantities[EstadoOCA.enDiscusion]} />
              }
              value='4'
            />
            <Tab
              label={<CustomTabLabel label='Cobrable' quantity={quantities[EstadoOCA.cobrable]} />}
              value='5'
            />
            <Tab
              label={
                <CustomTabLabel
                  label='Cobros realizados'
                  quantity={quantities[EstadoOCA.cobrada]}
                  tooltip
                />
              }
              value='6'
            />
            <Tab
              label={
                <CustomTabLabel label='Cerrada' quantity={quantities[EstadoOCA.cerrada]} tooltip />
              }
              value='7'
            />
            <Tab
              label={
                <CustomTabLabel
                  label='Expirada'
                  quantity={quantities[EstadoOCA.expirada]}
                  tooltip
                />
              }
              value='8'
            />
          </TabList>
        </Box>
        <TabPanel value='1'>
          {isOperador && (
            <Alert severity='info'>
              Como usuario representante de un CD puedes <b>Agregar</b> una Merma que llegó a tu CD
              (Destino) o puedes <b>Objetar</b> una que salió desde tu CD (Origen)
            </Alert>
          )}
          <CRUDingresada />
        </TabPanel>
        <TabPanel value='2'>
          {isTransportista && (
            <Alert severity='info'>
              Como usuario representante de una Empresa de Transportes puedes <b>Objetar</b> una
              Merma asociada a la Empresa
            </Alert>
          )}
          <CRUDobjetadaOrigen />
        </TabPanel>
        <TabPanel value='3'>
          {isOperador && (
            <Alert severity='info'>
              Como usuario representante de un CD Destino puedes <b>Contra responder</b> la objeción
              de un Tranportista para que un administrador de cobros decida sobre la Merma
            </Alert>
          )}
          <CRUDobjetadaTransportista />
        </TabPanel>
        <TabPanel value='4'>
          {isAdminCobros && (
            <Alert severity='info'>
              El <b>administrador de cobros</b> puede actuar como mediador en las OCAs que están en
              discusión
            </Alert>
          )}
          <CRUDenDiscusion />
        </TabPanel>
        <TabPanel value='5'>
          {isAdminCobros && (
            <Alert severity='info'>
              El administrador de cobros puede efectuar el cobro de las OCAs dentro de los{' '}
              <b>5 días</b> posteriores a que los registros se vuelvan cobrables.
            </Alert>
          )}
          <CRUDcobrable />
        </TabPanel>
        <TabPanel value='6'>
          <CRUDcobrada />
        </TabPanel>
        <TabPanel value='7'>
          <CRUDcerrada />
        </TabPanel>
        <TabPanel value='8'>
          <CRUDexpirada />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const TrazabilidadMermaConnected = connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  null
)(TrazabilidadMerma);
