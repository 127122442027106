import React from 'react';
import { Cobro } from '../../types';
import { WebEntity } from './types';
import { dateFormatFn } from '../../utils/utils';
import { DownloadFile } from '../utils';

const CobroEntity: WebEntity<Cobro> = {
  name: 'cobro',
  endpoint: 'cobros',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'ID cobro',
      accessorKey: 'cobroId',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Fecha Cobro',
      accessorKey: 'fechaCobro',
      columnType: 'dateTimeUTC',
      size: 160,
      Cell: ({ row }) => dateFormatFn(row.original.fechaCobro, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Detalle',
      accessorKey: 'detalle',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Causal',
      accessorKey: 'nombreCausal',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Evidencia OCA',
      accessorKey: 'evidencia',
      columnType: 'text',
      size: 160,
      omitExport: true,
      Cell: ({ row }) => {
        return row.original.evidencia ? (
          <DownloadFile
            fileName={row.original.evidencia}
            fileType='application/zip'
            buttonName='Evidencia OCA'
          />
        ) : (
          ''
        );
      }
    },
    {
      header: 'Transporte',
      accessorKey: 'nombreTransportista',
      columnType: 'text',
      size: 160
    },
    {
      header: 'Patente',
      accessorKey: 'patente',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Guia',
      accessorKey: 'guia',
      columnType: 'text',
      size: 140
    },
    {
      header: 'SKU',
      accessorKey: 'material',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Cantidad de cajas',
      accessorKey: 'cantidadCajas',
      columnType: 'numeric',
      size: 180
    },
    {
      header: 'Descripcion SKU',
      accessorKey: 'textoBreveMaterial',
      columnType: 'text',
      size: 180
    },
    {
      header: 'CD Origen',
      accessorKey: 'nombreCentroOrigen',
      columnType: 'text',
      size: 140
    },
    {
      header: 'CD Destino',
      accessorKey: 'nombreCentroDestino',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Fecha OCA',
      accessorKey: 'fechaOca',
      columnType: 'dateUTC',
      size: 160,
      Cell: ({ row }) => dateFormatFn(row.original.fechaOca, 'DD/MM/YYYY', true)
    },
    {
      header: 'Fecha ingreso OCA',
      accessorKey: 'fechaOcaIngresada',
      columnType: 'dateTimeUTC',
      size: 200,
      Cell: ({ row }) => dateFormatFn(row.original.fechaOcaIngresada, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Fecha objeción por CD Origen',
      accessorKey: 'fechaOcaObjetadaOrigen',
      columnType: 'dateTimeUTC',
      size: 250,
      Cell: ({ row }) =>
        dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Comentario Objeción por CD Origen',
      accessorKey: 'comentarioObjecionOrigen',
      columnType: 'text',
      size: 280,
      Cell: ({ row }) => row.original.comentarioObjecionOrigen
    },
    {
      header: 'Archivo objeción por CD Origen',
      accessorKey: 'nombreArchivoObjecionOrigen',
      columnType: 'text',
      size: 260,
      omitExport: true,
      Cell: ({ row }) => {
        return row.original.nombreArchivoObjecionOrigen ? (
          <DownloadFile
            fileName={row.original.nombreArchivoObjecionOrigen}
            fileType='application/pdf'
            buttonName='Objecion'
          />
        ) : (
          ''
        );
      }
    },
    {
      header: 'Fecha objeción por EETT',
      accessorKey: 'fechaOcaObjetadaTransportista',
      columnType: 'dateTimeUTC',
      size: 220,
      Cell: ({ row }) =>
        dateFormatFn(row.original.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Comentario Objeción por EETT',
      accessorKey: 'comentarioObjecionTransportista',
      columnType: 'text',
      size: 260,
      Cell: ({ row }) => row.original.comentarioObjecionTransportista
    },
    {
      header: 'Archivo objeción por EETT',
      accessorKey: 'nombreArchivoObjecionTransportista',
      columnType: 'text',
      size: 240,
      omitExport: true,
      Cell: ({ row }) => {
        return row.original.nombreArchivoObjecionTransportista ? (
          <DownloadFile
            fileName={row.original.nombreArchivoObjecionTransportista}
            fileType='application/pdf'
            buttonName='Objecion'
          />
        ) : (
          ''
        );
      }
    },
    {
      header: 'Comentario Contra Objeción por CD Destino',
      accessorKey: 'comentarioContraObjecionDestino',
      columnType: 'text',
      size: 330,
      Cell: ({ row }) => row.original.comentarioContraObjecionDestino
    },
    {
      header: 'Fecha cierre',
      accessorKey: 'fechaOcaCerrada',
      columnType: 'text',
      size: 160,
      Cell: ({ row }) => dateFormatFn(row.original.fechaOcaCerrada, 'DD/MM/YYYY HH:mm:ss', true)
    },
    {
      header: 'Comentario Administrador',
      accessorKey: 'comentarioAdministrador',
      columnType: 'text',
      size: 230,
      Cell: ({ row }) => row.original.comentarioAdministrador
    },
    {
      header: 'Estado anterior',
      accessorKey: 'estadoOcaAnterior',
      columnType: 'text',
      size: 170,
      Cell: ({ row }) => row.original.estadoOcaAnterior
    }
  ]
};

export default CobroEntity;
