import { FieldTypes } from '../../entities/types';
import { TipoUsuarioValues, UsuarioTransportista } from '../../types';
import { WebEntity } from './types';

const UsuarioTransportistaEntity: WebEntity<UsuarioTransportista> = {
  name: 'usuarioTransportista',
  endpoint: 'usuarioTransportista',
  referenceColumn: 'nombreTransportista',
  customButtonNames: {
    addButtonName: 'Agregar relación'
  },
  customDataActions: {
    addModalTitle: 'Agregar relación',
    editModalTitle: 'Editar relación'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'ID Usuarios',
      accessorKey: 'idUsuarios',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Transportista',
      accessorKey: 'nombreTransportista',
      columnType: 'text'
    },
    {
      header: 'Usuarios',
      accessorKey: 'nombresUsuarios',
      columnType: 'text',
      Cell: ({ row }) =>
        Array.isArray(row.original?.nombresUsuarios) ? row.original?.nombresUsuarios.join(', ') : ''
    }
  ],

  fields: [
    {
      name: 'Transportista',
      selector: '_id',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'transportista',
        endpoint: 'usuarioTransportista/transportistas',
        select: '_id',
        show: 'nombreTransportista',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Usuarios',
      selector: 'idUsuarios',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'usuarios',
        endpoint: 'usuarioTransportista/usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          tipoUsuario: [TipoUsuarioValues.Transportista]
        }
      }
    }
  ],

  editableFields: [
    {
      name: 'Transportista',
      selector: '_id',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'transportista',
        endpoint: 'usuarioTransportista/transportistas',
        select: '_id',
        show: 'nombreTransportista',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Usuarios',
      selector: 'idUsuarios',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'usuarios',
        endpoint: 'usuarioTransportista/usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          tipoUsuario: [TipoUsuarioValues.Transportista]
        }
      }
    }
  ]
};

export default UsuarioTransportistaEntity;
