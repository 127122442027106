import React from 'react';
import * as E from '../entities';
import { generateCRUD } from './CRUDGenerator';
import TrazabilidadModalForm from '../../components/TrazabilidadModalForm';
import { OCA, EstadoOCA, TipoCobro, TipoUsuarioValues } from '../../types';
import { WebEntity } from '../entities/types';
import { CrearCobro, DateFilter } from '../../components/SecondaryButton';
import { AdditionalTableActionsFunc, AdditionalTopTableComponents } from './types';

export const OcaCRUD = (
  tipoCobro: TipoCobro,
  estadoOCA: EstadoOCA,
  webEntity: WebEntity<OCA>,
  nombreTipoUsuario: TipoUsuarioValues | undefined
) => {
  const isAdminCobros = nombreTipoUsuario === TipoUsuarioValues.AdminCobros;
  const isCobrable = estadoOCA === EstadoOCA.cobrable;
  const isIngresada = estadoOCA === EstadoOCA.ingresada;
  const isCerrada = estadoOCA === EstadoOCA.cerrada;
  const isExpirada = estadoOCA === EstadoOCA.expirada;

  const additionalTableActions: AdditionalTableActionsFunc<OCA> | undefined =
    isCobrable && isAdminCobros
      ? ({ selected }) => (
          <CrearCobro selected={selected.map((obj) => obj.original)} tipoCobro={tipoCobro} />
        )
      : undefined;

  const additionalTopTableActions: AdditionalTopTableComponents[] | undefined = isIngresada
    ? [{ component: <TrazabilidadModalForm tipoCobro={tipoCobro} /> }]
    : isCerrada || isExpirada
    ? [
        {
          component: (
            <DateFilter
              webEntity={webEntity}
              viewsDate={['year', 'month']}
              additionalParams={{ tipoCobro, estadoOCA }}
            />
          )
        }
      ]
    : undefined;

  return generateCRUD({
    webEntity,
    query: { tipoCobro, estadoOCA },
    title: tipoCobro,
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false
    },
    additionalTableActions,
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['oca'],
      columnPinning: {
        right: isCobrable ? ['timeLeft'] : ['action']
      }
    },
    additionalTopTableActions,
    enableStickyFooter: true
  });
};

export const CobroCRUD = (tipoCobro: TipoCobro) =>
  generateCRUD({
    webEntity: E.CobroEntity,
    query: { tipoCobro },
    title: 'Cobros realizados',
    allowedActions: {
      export: true,
      add: false,
      delete: false,
      edit: false
    },
    additionalTopTableActions: [
      {
        component: (
          <DateFilter
            webEntity={E.CobroEntity}
            viewsDate={['year', 'month']}
            additionalParams={{ tipoCobro }}
          />
        )
      }
    ],
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['cobroId', 'oca']
    }
  });

export const UsuarioTransportistaCRUD = generateCRUD({
  webEntity: E.UsuarioTransportistaEntity,
  title: 'Relacion Usuario-Transportista',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true
  },
  initialState: {
    columnVisibility: {
      _id: false,
      idUsuarios: false
    }
  }
});

export const UsuarioCRUD = generateCRUD({
  webEntity: E.UsuarioEntity,
  title: 'Usuarios',
  query: {
    tipoUsuario: [TipoUsuarioValues.Transportista, TipoUsuarioValues.AdminCobros]
  },
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true
  },
  initialState: {
    columnVisibility: {
      _id: false
    },
    grouping: ['nombreTipoUsuario']
  }
});

export const TransportistasCRUD = generateCRUD({
  webEntity: E.TransportistasEntity,
  title: 'EETT',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const TipoCausalCRUD = generateCRUD({
  webEntity: E.TipoCausalEntity,
  title: 'Tipo causal',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});

export const DashboardCRUD = (tipoCobro: TipoCobro) =>
  generateCRUD({
    webEntity: E.DashboardEntity,
    title: 'Dashboard',
    query: { tipoCobro },
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      grouping: ['oca'],
      columnPinning: {
        left: ['oca', 'estadoOca']
      }
    },
    additionalTopTableActions: [
      {
        component: (
          <DateFilter
            webEntity={E.DashboardEntity}
            viewsDate={['year', 'month']}
            additionalParams={{ tipoCobro }}
          />
        )
      }
    ],
    enableStickyFooter: true
  });
