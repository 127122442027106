import { User } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn } from '../utils/utils';

const UsuarioEntity: WebEntity<User> = {
  name: 'usuario',
  endpoint: 'usuarios',
  referenceColumn: 'nombreUsuario', //TODO: make it multicolumn, the utils should have the 'not found' text as optional param

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Nombre Usuario',
      id: 'nombreUsuario',
      selector: (row) => row.nombreUsuario,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Nombre',
      id: 'nombre',
      selector: (row) => row.nombre,
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Email',
      id: 'emailUsuario',
      selector: (row) => row.emailUsuario,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Tipo',
      id: 'nombreTipoUsuario',
      selector: (row) => row.nombreTipoUsuario,
      columnType: 'text',
      sortable: true
    },
    {
      name: '¿Activo?',
      id: 'activo',
      selector: (row) => row.activo,
      columnType: 'text',
      valueToExport: ({ activo }) => booleanFormatFn(activo),
      format: ({ activo }) => booleanFormatFn(activo),
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'nombreUsuario',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'emailUsuario',
      type: FieldTypes.Email,
      required: true
    },
    {
      name: 'Clave',
      selector: 'claveUsuario',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: 'Tipo',
      selector: 'idTipoUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'tipoUsuarios',
        select: '_id',
        show: 'nombreTipoUsuario',
        filter: {
          activo: true
        }
      }
    }
  ],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'nombreUsuario',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'emailUsuario',
      type: FieldTypes.Email
    },
    {
      name: 'Clave',
      selector: 'claveUsuario',
      type: FieldTypes.Password
    },
    {
      name: '¿Activo?',
      selector: 'activo',
      type: FieldTypes.Boolean
    },
    {
      name: 'Tipo',
      selector: 'idTipoUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'tipoUsuarios',
        select: '_id',
        show: 'nombreTipoUsuario',
        filter: {
          activo: true
        }
      }
    }
  ]
};

export default UsuarioEntity;
