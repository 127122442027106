import { ReporteZonaCategoriaSKU } from '../../types';
import { FieldTypes, WebEntity } from '../types';
import { formatStringNumber } from '../../utils/utils';
import { updateFechaInventarioDependency } from './sharedComponents';

const ReporteSKUEntity: WebEntity<ReporteZonaCategoriaSKU> = {
  name: 'reporteZonaCategoriaSKU',
  endpoint: 'reportes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'Id',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Orden',
      id: 'orden',
      selector: (row) => row.orden,
      columnType: 'text',
      omitExport: true,
      wrap: true,
      omit: true
    },
    {
      name: 'Numero Antiguo Material',
      id: 'numeroAntiguoMaterial',
      selector: (row) => row.numeroAntiguoMaterial,
      columnType: 'text',
      valueToExport: ({ numeroAntiguoMaterial, orden }) =>
        numeroAntiguoMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ numeroAntiguoMaterial, orden }) =>
        numeroAntiguoMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Texto Breve Material',
      id: 'textoBreveMaterial',
      selector: (row) => row.textoBreveMaterial,
      columnType: 'text',
      valueToExport: ({ textoBreveMaterial, orden }) =>
        textoBreveMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ textoBreveMaterial, orden }) =>
        textoBreveMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Zona',
      id: 'zona',
      selector: (row) => row.zona,
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: '<21 dias',
      id: 'menor_21Dias',
      selector: (row) => row.menor_21Dias,
      columnType: 'numeric',
      format: ({ menor_21Dias }) => formatStringNumber(menor_21Dias, 0, 0),
      sortable: true
    },
    {
      name: '<41 dias',
      id: 'menor_41Dias',
      selector: (row) => row.menor_41Dias,
      columnType: 'numeric',
      format: ({ menor_41Dias }) => formatStringNumber(menor_41Dias, 0, 0),
      sortable: true
    },
    {
      name: '<61 dias',
      id: 'menor_61Dias',
      selector: (row) => row.menor_61Dias,
      columnType: 'numeric',
      format: ({ menor_61Dias }) => formatStringNumber(menor_61Dias, 0, 0),
      sortable: true
    },
    {
      name: '<121 dias',
      id: 'menor_121Dias',
      selector: (row) => row.menor_121Dias,
      columnType: 'numeric',
      format: ({ menor_121Dias }) => formatStringNumber(menor_121Dias, 0, 0),
      sortable: true
    },
    {
      name: '>121 dias',
      id: 'mayor_121Dias',
      selector: (row) => row.mayor_121Dias,
      columnType: 'numeric',
      format: ({ mayor_121Dias }) => formatStringNumber(mayor_121Dias, 0, 0),
      sortable: true
    },
    {
      name: 'Total General',
      id: 'totalGeneral',
      selector: (row) => row.totalGeneral,
      columnType: 'numeric',
      format: ({ totalGeneral }) => formatStringNumber(totalGeneral, 0, 0),
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Estado',
      selector: 'estado',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'estado',
        select: 'estado',
        show: 'nombre',
        data: [
          {
            estado: 'vencido',
            nombre: 'VENCIDO'
          },
          {
            estado: 'vencera',
            nombre: 'VENCERA'
          },
          {
            estado: 'todos',
            nombre: 'TODOS'
          }
        ]
      }
    },
    {
      name: 'Fecha Inventario',
      selector: 'fechaInventario',
      required: true,
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      updateDependency: updateFechaInventarioDependency
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.MultiReference,
      required: false,
      reference: {
        name: 'numeroAntiguoMaterial',
        endpoint: 'reportes/filtros/numeroAntiguoMaterial',
        select: 'sku',
        show: 'sku'
      }
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'textoBreveMaterial',
        endpoint: 'reportes/filtros/textoBreveMaterial',
        select: 'descripcionProducto',
        show: 'descripcionProducto'
      }
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'grupoProducto',
        endpoint: 'reportes/filtros/grupoDeProducto',
        select: 'grupoProducto',
        show: 'grupoProducto'
      }
    },
    {
      name: 'CD',
      selector: 'nombreCentro',
      required: true,
      type: FieldTypes.MultiReference,
      reference: {
        name: 'cd',
        endpoint: 'reportes/filtros/cd',
        select: 'nombreCentro',
        show: 'nombreCentro'
      }
    },
    {
      name: 'Marca',
      selector: 'marca',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'marca',
        endpoint: 'reportes/filtros/grupoDeMarca',
        select: 'marca',
        show: 'marca'
      }
    },
    {
      name: 'Zonas',
      selector: 'zona',
      required: false,
      type: FieldTypes.MultiReference,
      reference: {
        name: 'zona',
        endpoint: 'reportes/filtros/zona',
        select: 'zona',
        show: 'zona'
      }
    },
    {
      name: 'Vence',
      selector: 'vencePerfilEnvejecimiento',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'vencePerfilEnvejecimiento',
        select: 'value',
        show: 'option',
        data: [
          { option: 'SI', value: 'SI' },
          { option: 'NO', value: 'NO' }
        ]
      }
    }
  ]
};

export default ReporteSKUEntity;
