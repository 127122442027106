import React, {
  CSSProperties,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { CSVLink } from 'react-csv';
import { Icon } from 'semantic-ui-react';
import { ColumnType, ExtendedColumnDef } from '../../entities/types';
import { convertArrayOfObjectToFieldsMatrix, downloadXLSX } from '../../../utils/export';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  BodyRowProps,
  CRUDAllowedActions,
  InitialState,
  MRT_Row_WithOriginal
} from '../types';
import {
  useMaterialReactTable,
  MRT_RowData,
  MaterialReactTable,
  MRT_TableInstance
} from 'material-react-table';
import { Box, Button, CircularProgress } from '@mui/material';
import { warningPopAlert } from '../../../components/PopAlert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { BasicEntity, SimpleObject } from '../../../types';
import '../../../css/AppHome.css';

const CustomExport: FunctionComponent<{
  exportDataHandler: (when?: string) => Promise<any[]>;
  columnTypes: ColumnType[];
  fileName?: string;
}> = ({ exportDataHandler, columnTypes, fileName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const exportData = async () => {
    setLoading(true);

    const data = await exportDataHandler();

    if (data.length === 0) {
      setLoading(false);
      return warningPopAlert(`No se encontraron registros que exportar`);
    }

    downloadXLSX(data, columnTypes, fileName);

    setLoading(false);
    return;
  };

  return (
    <Button onClick={exportData} disabled={loading} variant='contained' color='success'>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

export const ExportCSV: FunctionComponent<{
  onExport: () => string[][];
}> = ({ onExport }) => (
  <CSVLink
    style={{
      display: 'inline-block',
      padding: '10px 20px',
      marginInline: '1em',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#4CAF50',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer'
    }}
    data={onExport()}
    filename={`export_${new Date().toISOString().split('T')[0]}.csv`}
    separator={';'}>
    <Icon name={'download'} />
  </CSVLink>
);

const Export: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
}> = ({ onExport, columnTypes }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes);

      setLoading(false);
    }, 500);
  };

  return (
    <Button onClick={handleExport} disabled={loading} variant='contained' color='success'>
      <FileDownloadOutlinedIcon />
      Descargar Excel
    </Button>
  );
};

type Props<T> = {
  columns: ExtendedColumnDef<T>[];
  tableData: MRT_RowData[];
  fetchData: () => Promise<any>;
  addAction?: JSX.Element;
  addMultiLineAction?: JSX.Element;
  deleteAction?: (
    getSelectedData: () => { ids: string[]; refs: string },
    table: MRT_TableInstance<MRT_RowData>
  ) => JSX.Element;
  secondaryAction?: AdditionalTableActionsFunc<T>;
  allowedActions?: Partial<CRUDAllowedActions<T>>;
  tableName?: string;
  editAction?: (row: BasicEntity) => JSX.Element;
  additionalTableProps?: AdditionalTableProps<T>;
  customExport?: {
    exportDataHandler: (when?: string) => Promise<any[]>;
    columnTypes: ColumnType[];
    fileName?: string;
  };
  initialState?: InitialState;
  enablePagination?: boolean;
  enableColumnVirtualization?: boolean;
  enableRowVirtualization?: boolean;
  optionsPagination?: {
    initialStatePagination?: {
      pagination?: { pageSize: number; pageIndex: number };
    };
  };
  columnsHeaderStyle?: CSSProperties;
  loadingTable?: boolean;
  additionalTopTableActions?: JSX.Element;
  enableStickyFooter?: boolean;
  bodyRowProps?: BodyRowProps;
};

const GenericTable = <T,>({
  // Required
  columns,
  tableData,
  fetchData,
  // Optionals
  enablePagination = true,
  addAction,
  addMultiLineAction,
  deleteAction,
  editAction,
  secondaryAction,
  allowedActions,
  tableName = '',
  customExport,
  columnsHeaderStyle,
  initialState,
  enableColumnVirtualization = false,
  enableRowVirtualization = false,
  optionsPagination,
  loadingTable,
  additionalTopTableActions,
  enableStickyFooter = false,
  bodyRowProps
}: Props<T>) => {
  // Server side pagination
  const [isloading, setLoading] = useState<boolean>(true);

  // Mount Flag
  const isMounted = useRef(true);

  const {
    add: canAdd,
    multiLineForm: canMultiLine,
    delete: canDelete,
    export: canExport,
    select: canSelect,
    edit: canEdit,
    editCondition
  } = {
    add: true,
    multiLineForm: true,
    delete: true,
    export: false,
    select: false,
    edit: true,
    ...allowedActions
  };

  const fetchDataPage = useMemo(
    () => async () => {
      setLoading(true);

      await fetchData();
      if (!isMounted.current) {
        return;
      }

      setLoading(false);
    },
    [fetchData]
  );

  // ComponentDidMount
  useEffect(() => {
    // COMPONENT DID UNMOUNT
    return () => {
      isMounted.current = false;
    };
  }, []);

  // ComponentDidUpdate (fetchDataPage)
  useEffect(() => {
    fetchDataPage();
  }, [fetchDataPage]);

  useEffect(() => {
    let isTime: NodeJS.Timeout;
    if (!loadingTable) {
      isTime = setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    return () => {
      clearTimeout(isTime);
    };
  }, [loadingTable]);

  const renderExport = useCallback(
    (data: SimpleObject[], columns: ExtendedColumnDef<T>[]) => {
      if (canExport) {
        if (customExport) {
          return (
            <CustomExport
              exportDataHandler={customExport.exportDataHandler}
              columnTypes={customExport.columnTypes}
              fileName={customExport.fileName}
            />
          );
        }
        return (
          <Export
            onExport={() =>
              convertArrayOfObjectToFieldsMatrix(
                data,
                columns.map((obj) => obj.accessorKey),
                columns.map((obj) => obj.header)
              )
            }
            columnTypes={columns.map((obj) => obj.columnType)}
          />
        );
      }
      return <></>;
    },
    [canExport, customExport]
  );

  const column = useMemo(() => columns, [columns]);

  const renderTopToolbarCustomActions = useCallback(
    ({ table }: { table: MRT_TableInstance<MRT_RowData> }) => {
      const actions: Array<JSX.Element> = [];

      const visibleColumns = table.getVisibleFlatColumns().map((obj) => obj.id);
      const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
      const exportColumns = columns.filter(
        (obj) =>
          typeof obj.accessorKey === 'string' &&
          visibleColumns.includes(obj.accessorKey) &&
          !obj.omitExport
      );

      const data = rows.map((row) =>
        exportColumns.reduce((acc, obj) => {
          const key = obj.accessorKey as string;
          acc[key] = obj.valueToExport
            ? obj.valueToExport(row)
            : row.hasOwnProperty(key)
            ? row[key]
            : '';
          return acc;
        }, {} as Record<string, any>)
      );

      actions.push(renderExport(data, exportColumns));

      if (canDelete && deleteAction) {
        const getSelectedData = () => {
          const rowsToDelete = table.getSelectedRowModel().rows.map((row) => row.original);
          const ids = rowsToDelete.map((row) => row._id);
          const refs = `¿Seguro que deseas eliminar ${rowsToDelete.length} registros?`;

          return { ids, refs };
        };

        if (
          table.getIsSomeRowsSelected() ||
          table.getIsAllRowsSelected() ||
          table.getGroupedSelectedRowModel().rows.length > 0 ||
          table.getGroupedSelectedRowModel().flatRows.length > 0
        ) {
          actions.push(deleteAction(getSelectedData, table));
        }
      }
      if (secondaryAction) {
        actions.push(
          secondaryAction({
            selected: table.getSelectedRowModel().rows as MRT_Row_WithOriginal<T>[]
          })
        );
      }
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            flex: 1
          }}>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </Box>
      );
    },
    [columns, canDelete, deleteAction, renderExport, secondaryAction]
  );

  const table = useMaterialReactTable({
    data: tableData,
    columns: column,
    enableGrouping: true,
    enablePagination: enablePagination,
    enableSelectAll: canDelete || canSelect || !!secondaryAction,
    layoutMode: 'grid',
    enableRowSelection: canDelete || canSelect || !!secondaryAction,
    positionToolbarAlertBanner: 'bottom',
    selectAllMode: 'all',
    enableStickyFooter,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Acciones',
        minSize: 80,
        maxSize: 100
      },
      'mrt-row-select': {
        minSize: 42,
        maxSize: 60
      },
      'mrt-row-expand': {
        header: 'Expandir',
        minSize: 80,
        maxSize: 100,
        enableResizing: true
      }
    },
    initialState: {
      grouping: initialState?.grouping,
      columnVisibility: initialState?.columnVisibility || {},
      columnPinning: {
        left: [
          'mrt-row-actions',
          'mrt-row-select',
          'mrt-row-expand',
          ...(initialState?.columnPinning?.left || [])
        ],
        right: initialState?.columnPinning?.right
      },
      pagination: {
        pageSize: optionsPagination?.initialStatePagination?.pagination?.pageSize || 100,
        pageIndex: optionsPagination?.initialStatePagination?.pagination?.pageIndex || 0
      },
      density: 'compact',
      showGlobalFilter: true
    },
    enableBottomToolbar: true,
    enableColumnResizing: true,
    enableGlobalFilterModes: true,
    enableColumnPinning: true,
    enableColumnVirtualization: enableColumnVirtualization,
    enableRowVirtualization: enableRowVirtualization,
    localization: MRT_Localization_ES,
    enableStickyHeader: true,
    enableColumnActions: true,
    enableExpandAll: false,
    enableColumnOrdering: true,
    positionGlobalFilter: 'left',
    muiSearchTextFieldProps: {
      fullWidth: true
    },
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    enableRowActions: canEdit,
    renderRowActions:
      canEdit && editAction
        ? ({ row }) => {
            if (editCondition && !editCondition(row?.original as T)) {
              return undefined;
            }
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1vw',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                {editAction(row?.original as BasicEntity)}
              </Box>
            );
          }
        : undefined,
    renderTopToolbarCustomActions: (props) => renderTopToolbarCustomActions(props),
    getRowId: (row) => row._id,
    muiTableHeadCellProps: () => ({
      sx: columnsHeaderStyle
    }),
    muiTableBodyRowProps: bodyRowProps
  });

  return (
    <div className='generic-new-table-container'>
      {tableName && (
        <div className='generic-new-table-top-container'>
          <h2 className='generic-new-table-title'>{tableName}</h2>
          <div className='generic-new-table-actions-container'>
            {additionalTopTableActions && additionalTopTableActions}
            {canMultiLine && addMultiLineAction}
            {canAdd && addAction}
          </div>
        </div>
      )}
      {isloading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}
    </div>
  );
};

export default GenericTable;
