import React, { FunctionComponent } from 'react';
import { Button, Icon, Select } from 'semantic-ui-react';
import styled from 'styled-components';

type PaginationProps = {
  rowCount: number;
  currentPage: number;
  availablePages: number;
  currentRowsPerPage: number;
  onChangePage: (page: number, totalRows: number) => void;
  onChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => void;
};

const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ theme }: { theme: any }) => theme.pagination.style};
`;

const Pagination: FunctionComponent<PaginationProps> = ({
  rowCount,
  currentPage,
  currentRowsPerPage,
  availablePages,
  onChangePage,
  onChangeRowsPerPage
}): JSX.Element => {
  const options = [
    { key: '10', text: '10', value: 10 },
    { key: '15', text: '15', value: 15 },
    { key: '20', text: '20', value: 20 },
    { key: '25', text: '25', value: 25 },
    { key: '30', text: '30', value: 30 },
    { key: 'all', text: 'Todos', value: rowCount }
  ];

  return (
    <PaginationWrapper className='rdt_Pagination'>
      <div style={{ marginRight: '1em' }}>Filas por página</div>
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '1em'
        }}>
        <Select
          onChange={(_, data) => onChangeRowsPerPage(data.value as number, currentPage)}
          fluid
          options={options}
          placeholder='Filas'
          value={currentRowsPerPage === rowCount ? options[5].value : currentRowsPerPage}
        />
      </div>

      <Button
        type='button'
        onClick={() => onChangePage(currentPage - 1, rowCount)}
        disabled={currentPage === 1}>
        <Icon name={'step backward'} />
      </Button>
      <div style={{ margin: '0 10px 0 10px' }}>
        {currentPage} de {availablePages}
      </div>
      <Button
        type='button'
        onClick={() => onChangePage(currentPage + 1, rowCount)}
        disabled={currentPage === availablePages}>
        <Icon name={'step forward'} />
      </Button>

      <div style={{ margin: '0 10px 0 10px' }}>Total de elementos: {rowCount}</div>
    </PaginationWrapper>
  );
};

export default Pagination;
