import { Inventario } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const InventarioEnvasesEntity: WebEntity<Inventario> = {
  name: 'inventarioEnvases',
  endpoint: 'inventarioEnvases',
  referenceColumn: 'material',

  tableColumns: [
    {
      name: 'ID',
      id: 'id',
      selector: (row) => row.id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'ID Documento',
      id: 'idDocumento',
      selector: (row) => row.idDocumento,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Bodega',
      id: 'bodega',
      selector: (row) => row.bodega,
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro',
      id: 'idCentro',
      selector: (row) => row.idCentro,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Material',
      id: 'material',
      selector: (row) => row.material,
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Descripción',
      id: 'textoBreveMaterial',
      selector: (row) => row.textoBreveMaterial,
      columnType: 'text',
      sortable: true,
      wrap: true,
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem',
            padding: 5
          }
        }
      ],*/
      center: true
    },
    {
      name: 'Cantidad Contada',
      id: 'cantidadContada',
      selector: (row) => row.cantidadContada,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Fecha Registro',
      id: 'fechaRegistro',
      selector: (row) => row.fechaRegistro,
      columnType: 'dateUTC',
      format: ({ fechaRegistro }) => dateFormatFn(fechaRegistro, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Utilizado',
      id: 'tipoStock',
      selector: (row) => row.tipoStock,
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario',
      id: 'nombreUsuario',
      selector: (row) => row.nombreUsuario,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Almacen',
      id: 'almacen',
      selector: (row) => row.almacen,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Lote',
      id: 'lote',
      selector: (row) => row.lote,
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Pedido Cliente',
      id: 'pedidoCliente',
      selector: (row) => row.pedidoCliente,
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Numero Hu',
      id: 'numeroHu',
      selector: (row) => row.numeroHu,
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    }
  ],

  fields: [],

  editableFields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      type: FieldTypes.Reference,
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: 'nomenclaturas/envases',
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura',
        endpointQuery: ({ material }) => ({ material })
      }
    }
  ]
};

export default InventarioEnvasesEntity;
