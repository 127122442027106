import { Documento, IdTipoDocumentoValues } from '../types';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';
import { getUncatchEndpointData } from '../utils/request';
import { dateFormatFn } from '../utils/utils';

const updateDependencyFecha =
  (
    fieldName: string,
    fieldToUpdate:
      | 'fechaDocumento'
      | 'fechaDocumentoFin'
      | 'fechaCargaDocumento'
      | 'fechaCargaDocumentoFin'
  ): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldToUpdate);

      const fieldValueDateFormat = new Date(fieldValue);
      const fieldValueToUpdateDateFormat = new Date(fieldValueToUpdate);

      const checkIfIsStart = () =>
        fieldName === 'fechaDocumento' || fieldName === 'fechaCargaDocumento';
      const checkIfIsEnd = () =>
        fieldName === 'fechaDocumentoFin' || fieldName === 'fechaCargaDocumentoFin';
      const checkIfIsMajor = () => fieldValueDateFormat > fieldValueToUpdateDateFormat;
      const checkIfIsMinus = () => fieldValueDateFormat < fieldValueToUpdateDateFormat;

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if ((checkIfIsEnd() && checkIfIsMinus()) || (!checkIfIsEnd() && checkIfIsMajor())) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        (!fieldValue && fieldValueToUpdate && checkIfIsStart()) ||
        (fieldValue && !fieldValueToUpdate && checkIfIsEnd())
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const updateDependencyZona =
  (fieldName: string, fieldToUpdate: 'bodega'): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldToUpdate);
      setLoadings([field], true);
      const nombreCentro = getFieldValue(fieldName);

      if (nombreCentro) {
        const zona = await getUncatchEndpointData({
          endpoint: 'centroInventario/zonaDeTransporte',
          query: {
            nombreCentro: nombreCentro
          }
        });

        await updateFieldValue(fieldToUpdate, zona.zonaDeTransporte);
      }

      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const updateDependencyMaterial =
  (fieldName: string, fieldsToUpdate: ['textoBreveMaterial', 'tipoStock']): UpdateDependencyFunc =>
  async (
    fieldValue,
    getField,
    setLoadings,
    updateFetchedFields,
    updateFieldValue,
    getFieldValue,
    getRow
  ): Promise<void> => {
    try {
      const fieldTextoBreveMaterial = getField(fieldsToUpdate[0]);
      const fieldTipoStock = getField(fieldsToUpdate[1]);
      setLoadings([fieldTextoBreveMaterial], true);
      setLoadings([fieldTipoStock], true);

      const material = (getFieldValue(fieldName + `_${getRow()}`) || fieldValue || '').trim();

      const resTextoBreveMaterial = await getUncatchEndpointData({
        endpoint: 'sku/getTextoBreveMaterial',
        query: { material }
      });

      const resNomenclatura = await getUncatchEndpointData({
        endpoint: 'nomenclaturas/envases',
        query: { material }
      });

      updateFetchedFields({ [fieldsToUpdate[1] + `_${getRow()}`]: { data: resNomenclatura } });

      await updateFieldValue(fieldsToUpdate[0], resTextoBreveMaterial.textoBreveMaterial, true);

      setLoadings([fieldTextoBreveMaterial], false);
      setLoadings([fieldTipoStock], false);
    } catch (error) {
      console.log(error);
    }
  };

const DocumentoEnvasesEntity: WebEntity<Documento> = {
  name: 'documentoEnvases',
  endpoint: 'documentosEnvases',
  referenceColumn: 'idDocumento',

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Documento ID',
      id: 'idDocumento',
      selector: (row) => row.idDocumento,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Usuario',
      id: 'nombreUsuario',
      selector: (row) => row.nombreUsuario,
      columnType: 'text'
    },
    {
      name: 'Estado Documento',
      id: 'descripcionEstadoDocumento',
      selector: (row) => row.descripcionEstadoDocumento,
      columnType: 'text',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.idEstadoDocumento === 3,
          style: {
            color: 'var(--ccu-verde-claro, #64A70B)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 1,
          style: {
            color: 'var(--ccu-amarillo, #FFB81C)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        },
        {
          when: (row) => row.idEstadoDocumento === 4,
          style: {
            color: 'var(--ccu-amarillo, red)',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal'
          }
        }
      ]
    },
    {
      name: 'Fecha Recuento',
      id: 'fechaDocumento',
      selector: (row) => row.fechaDocumento,
      columnType: 'dateUTC',
      format: ({ fechaDocumento: d }) => dateFormatFn(d, 'DD/MM/YYYY', true),
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Carga',
      id: 'fechaCargaDocumento',
      selector: (row) => row.fechaCargaDocumento,
      columnType: 'dateTime',
      format: ({ fechaCargaDocumento: d }) => dateFormatFn(d),
      wrap: true,
      sortable: true
    },
    {
      name: 'Zona',
      id: 'nombreCentro',
      selector: (row) => row.nombreCentro,
      columnType: 'text',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Fecha de Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      required: true,
      multiInput: {
        multiField: false,
        isDuplicable: false
      }
    },
    {
      name: 'Comentario',
      selector: 'idComentario',
      type: FieldTypes.Reference,
      required: true,
      multiInput: {
        multiField: false,
        isDuplicable: false
      },
      reference: {
        name: 'idComentario',
        endpoint: `comentario/tipoDocumento/${IdTipoDocumentoValues.idConteoEnvase}`,
        select: 'idComentario',
        show: 'comentario'
      }
    },
    {
      name: 'Zona',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Reference,
      required: true,
      updateDependency: updateDependencyZona('zonaDeTransporte', 'bodega'),
      multiInput: {
        multiField: false,
        isDuplicable: true
      },
      reference: {
        name: 'zone',
        endpoint: 'centroInventario/zonas',
        select: 'nombreCentro',
        show: 'nombreCentro'
      }
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      required: true,
      hidden: true,
      multiInput: {
        multiField: true,
        isDuplicable: true,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      columnClassName: 'col-sm-1',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      required: true,
      hidden: true,
      defaultValue: 'EN01',
      multiInput: {
        multiField: true,
        isDuplicable: true,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Material',
      selector: 'material',
      formatStr: (value) => (value || '').replace(' ', '').toUpperCase(),
      type: FieldTypes.Text,
      columnClassName: 'col-sm-2',
      required: true,
      updateDependency: updateDependencyMaterial('material', ['textoBreveMaterial', 'tipoStock']),
      timerUpdateDependency: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      columnClassName: 'col',
      disabled: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      }
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      required: true,
      hidden: true,
      defaultValue: new Date(),
      multiInput: {
        multiField: true,
        isDuplicable: false,
        setDefaultValueOnNewRow: {
          previous: true
        }
      }
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text,
      columnClassName: 'col-sm-1',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: false
      }
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      type: FieldTypes.Reference,
      columnClassName: 'col-sm-4',
      required: true,
      multiInput: {
        multiField: true,
        isDuplicable: true
      },
      reference: {
        name: 'descripcionNomenclatura',
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura',
        data: []
      }
    }
  ],

  editableFields: [
    {
      name: 'Estado',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      timezone: 'UTC',
      onlyAdmin: true
    }
  ],

  filterFields: [
    {
      name: 'Usuario',
      selector: 'idUsuario',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'usuario',
        endpoint: 'usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          activo: true
        }
      },
      onlyAdmin: true
    },
    {
      name: 'Estado Documento',
      selector: 'idEstadoDocumento',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'estadoDocumento',
        endpoint: 'estadoDocumento',
        select: '_id',
        show: 'descripcionEstadoDocumento',
        filter: {}
      }
    },
    {
      name: 'Fecha Recuento',
      selector: 'fechaDocumento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumento', 'fechaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaDocumentoFin',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 1,
      required: false,
      updateDependency: updateDependencyFecha('fechaDocumentoFin', 'fechaDocumento')
    },
    {
      name: 'Fecha Carga',
      selector: 'fechaCargaDocumento',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumento', 'fechaCargaDocumentoFin')
    },
    {
      name: '-',
      selector: 'fechaCargaDocumentoFin',
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      groupNumber: 2,
      required: false,
      updateDependency: updateDependencyFecha('fechaCargaDocumentoFin', 'fechaCargaDocumento')
    }
  ]
};

export default DocumentoEnvasesEntity;
