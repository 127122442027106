import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DatePicker from 'react-date-picker';
import { useRut } from 'react-rut';
import Webcam from 'react-webcam';
import { Button, Form, Input, Label, Modal } from 'reactstrap';
import { Button as ButtonB, Checkbox, Dropdown, Icon, Image } from 'semantic-ui-react';
import { FieldTypes } from '../../entities/types';
import { ImageFormats } from '../../types';
import { toBase64 } from '../../utils/convert';
import { getEntity } from '../../utils/request';
import { capitalize, fusionFields, cleanTextDate } from '../../utils/utils';
import { warningPopAlert } from '../PopAlert';
import { addObjectToStorage, getObjectFromStorage, removeFromStorage } from '../../utils/storage';
import GenericIcons from './GenericIcons';
import Swal from 'sweetalert2';

const PHOTO_WIDTH = 1920;
const PHOTO_HEIGHT = 1080;

const FieldInputText = ({ field, name, onChange, autoFocus, value, disabled = false }) => (
  <Input
    name={name}
    type={field.type}
    placeholder={capitalize(field.name)}
    className='form-control'
    onChange={onChange}
    autoFocus={autoFocus}
    value={value}
    disabled={disabled}
  />
);

const pickupDateOnChange = (callback) => (date) => callback(cleanTextDate(date));
export const DatePickerComponent = ({
  name,
  value,
  format = 'dd/MM/yyyy',
  onChange,
  minDate = undefined,
  maxDate = undefined,
  disabled = false
  //locale = 'es-CL'
}) => {
  if (typeof value === 'string' && /^\d{4}\/\d{2}\/\d{2}$/.test(value)) {
    const splittedDate = value.split('/');
    value = `${splittedDate[1]}-${splittedDate[2]}-${splittedDate[0]}`;
  }

  const localValue = typeof value === 'string' ? new Date(value) : value;
  return (
    <div>
      <div
        style={{
          width: '180px',
          padding: '10px',
          border: '1px solid black',
          borderRadius: 8
        }}>
        <DatePicker
          name={name}
          value={localValue}
          format={format}
          onChange={pickupDateOnChange(onChange)}
          dayPlaceholder={'Día'}
          monthPlaceholder={'Mes'}
          yearPlaceholder={'Año'}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          //locale={locale}
        />
      </div>
    </div>
  );
};

const FieldInputRut = ({ field, onChange, autoFocus, value }) => {
  // TODO: NO DEJAR ENVIAR EL FORMULARIO SI isValid ES false
  // TODO: ARREGLAR LA OBTENCION DEL RUT COMPLETO FORMATEADO
  // TODO: QUE SE SETEE EL VALOR CUANDO SE SELECCIONA EL VALOR GUARDADO DESDE EL NAVEGADOR
  const [{ formattedValue, rawValue }, setRut] = useRut();

  const localOnChange = (e) => {
    setRut(e.target.value);
    onChange({ target: { value: rawValue, name: field.selector } });
  };

  return (
    <Input
      name={field.selector}
      type={field.type}
      placeholder={capitalize(field.name)}
      className='mb-3'
      onChange={localOnChange}
      autoFocus={autoFocus}
      value={formattedValue}
    />
  );
};

const PhotoTaker = ({ isOpen, closeModal, photoCallback, format = ImageFormats.Png }) => {
  const webCamRef = React.useRef();

  const capture = () => {
    const imageSrc = webCamRef ? webCamRef.current.getScreenshot() : null;
    photoCallback(imageSrc);
    closeModal();
  };

  const videoConstraints = {
    width: PHOTO_WIDTH,
    height: PHOTO_HEIGHT,
    facingMode: 'environment'
  };

  return (
    <Modal isOpen={isOpen} autoFocus={true} toggle={closeModal}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Webcam
          ref={webCamRef}
          videoConstraints={videoConstraints}
          pscreenshotformat={`image/${format}`}
          width={'100%'}
          height={'100%'}
        />
        <ButtonB style={{ width: '100%' }} type='button' onClick={capture}>
          <Icon name='camera' />
          Capturar
        </ButtonB>
      </div>
    </Modal>
  );
};

const PhotoDisplayer = ({ isOpen, closeModal, onDelete, img }) => {
  return (
    <Modal isOpen={isOpen} autoFocus={true} toggle={closeModal}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Image wrapped src={img} onClick={closeModal} />
        <ButtonB style={{ width: '100%' }} type='button' onClick={onDelete}>
          <Icon name='trash' />
          Eliminar
        </ButtonB>
      </div>
    </Modal>
  );
};

const PhotoHolder = ({ onChange, constraints = {} }) => {
  const [images, setImages] = React.useState([]);
  const [isCameraOpen, setIsCameraOpen] = React.useState(false);
  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState({ idx: -1, img: null });
  const { max = 0, format = ImageFormats.Png } = constraints;

  const switchModal = (modalFn, isOpen) => () => modalFn(isOpen);
  const onClickImage = (img) => () => {
    setSelectedImg(img);
    switchModal(setIsImageOpen, true)();
  };

  const onDeleteImage = (idx) => () => {
    if (idx !== -1) {
      const updatedImages = [...images];
      updatedImages.splice(idx, 1);
      setImages(updatedImages);
      setSelectedImg({ idx: -1, img: null });
      switchModal(setIsImageOpen, false)();
    }
  };

  const photoCallBack = (photo) => {
    const updatedImages = [...images, photo];
    setImages(updatedImages);
    onChange(updatedImages);
  };

  const onSelectStoredPhoto = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = await toBase64(event.target.files[0]).catch((e) => Error(e));
      if (img instanceof Error) {
        console.log('Error: ', img.message);
        return;
      }

      photoCallBack(img);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {images.map((img, idx) => (
        <div
          key={`form_img_${idx}`}
          onClick={onClickImage({ img, idx })}
          style={{ marginLeft: '1em', marginTop: '1em' }}>
          <Image wrapped size='tiny' src={img} />
        </div>
      ))}
      {(max === 0 || max > images.length) && (
        <div style={{ margin: '1em 0 0 1em' }}>
          <ButtonB type='button' icon='camera' onClick={switchModal(setIsCameraOpen, true)} />
          <input
            id='upload-button'
            type='file'
            accept='image/*'
            style={{ display: 'none' }}
            name='storedImage'
            onChange={onSelectStoredPhoto}
          />
          <label htmlFor='upload-button' style={{ cursor: 'pointer' }}>
            <Icon name='upload' color='black' size='big' inverted style={{ marginLeft: '0.2em' }} />
          </label>
        </div>
      )}

      <PhotoDisplayer
        isOpen={isImageOpen}
        closeModal={switchModal(setIsImageOpen, false)}
        onDelete={onDeleteImage(selectedImg.idx)}
        img={selectedImg.img}
      />
      <PhotoTaker
        isOpen={isCameraOpen}
        closeModal={switchModal(setIsCameraOpen, false)}
        photoCallback={photoCallBack}
        format={format}
      />
    </div>
  );
};

export const GenericDropDownList = ({
  fieldName,
  onChange,
  data,
  valueField,
  textField,
  value,
  required,
  loading,
  dropDownOptions
}) => {
  const options = React.useMemo(
    () =>
      data && data.length > 0
        ? data.map((opt, idx) => ({
            key: `${fieldName}_${idx}`,
            value: opt[valueField],
            text: fusionFields(textField)(opt)
          }))
        : [],
    [data, valueField, textField, fieldName]
  );

  // NOTE: This make sure to set a safe value depending on the dropdown field 'multiple'
  const safeValue = dropDownOptions && dropDownOptions.multiple && !value ? [] : value;

  return (
    <Dropdown
      {...{
        className: 'mb-3',
        placeholder: `Seleccione ${fieldName}`,
        fluid: true,
        lazyLoad: true,
        search: true,
        selection: true,
        options,
        onChange,
        value: safeValue,
        clearable: !required,
        loading,
        disabled: loading,
        ...dropDownOptions
      }}
    />
  );
};

GenericDropDownList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  textField: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  dropDownOptions: PropTypes.object
};

// TODO: NOT Redraw all the form on any onChange.
// the onChange should redraw only its own component, not all the form
export class GenericMultiInputForm extends Component {
  state = {};
  fetchedFields = null;
  referenceHeaderFields = [];
  referenceMultiFields = [];
  allowedFields = [];
  multiFields = [];
  headerFields = [];
  multiInputFields = [];
  timers = {};
  listCheckbox = [];
  allCheckboxSelect = false;
  constructor(props) {
    super(props);

    const { fields, isAdmin, nameEndPoint } = props;

    this.nameEndPoint = nameEndPoint;

    this.state.isLoading = false;

    this.allowedFields = fields.filter((f) => !(f.onlyAdmin && !isAdmin));
    this.referenceHeaderFields = this.allowedFields.filter(
      (f) =>
        (f.type === FieldTypes.Reference || f.type === FieldTypes.MultiReference) &&
        !f.multiInput?.multiField
    );
    this.referenceMultiFields = this.allowedFields.filter(
      (f) =>
        (f.type === FieldTypes.Reference || f.type === FieldTypes.MultiReference) &&
        f.multiInput?.multiField
    );

    this.headerFields = this.allowedFields.filter((f) => !f.multiInput?.multiField);
    this.multiFields = this.allowedFields.filter((f) => f.multiInput?.multiField);
    this.multiInputFields.push(this.multiFields);

    this.allCheckboxSelect = false;
    this.listCheckbox[0] = { name: 'chbx_0', checked: false };

    const getDataLocalStorage = getObjectFromStorage(this.nameEndPoint);

    const initializeData = async () => {
      // checks if there is data stored in memory for this.nameEndPoint
      if (
        getDataLocalStorage &&
        'multiInputFields' in getDataLocalStorage &&
        'state' in getDataLocalStorage
      ) {
        this.multiInputFields = [];
        this.listCheckbox = [];
        this.allCheckboxSelect = false;
        const countRows = getDataLocalStorage.multiInputFields.length;
        for (let row = 0; row < countRows; row++) {
          this.multiInputFields.push(this.multiFields);
          this.listCheckbox[row] = { name: `chbx_${row}`, checked: false };
          for (let numField = 0; numField < this.multiFields.length; numField++) {
            if (this.multiFields[numField].defaultValue) {
              this.state[`${this.multiFields[numField].selector}_${row}`] =
                this.multiFields[numField].defaultValue;
            }
          }
        }
        this.state = { ...this.state, ...getDataLocalStorage.state };
        await this.updateLocalStorage(this.nameEndPoint);
        this.focusFirstField(this.multiInputFields.length - 1);

        // If there are rows and fields with updateDependency, it executes them for each row
        this.multiUpdateDependency(countRows);
        return;
      }

      this.initialState();
      await this.updateLocalStorage(this.nameEndPoint);
      this.focusFirstField(this.multiInputFields.length - 1);
    };
    initializeData();
  }

  focusFirstField = (pos) => {
    const firstFieldNotHidden = this.multiFields.find((field) => !field.hidden);
    const selector = `${firstFieldNotHidden.selector}_${pos}`;
    const elemento = document.getElementsByName(selector);
    const field = elemento[0];
    if (field) {
      field.focus();
    }
  };

  updateLocalStorage = async (name) => {
    const dataLocalStorage = {
      multiInputFields: this.multiInputFields,
      state: this.state
    };
    await addObjectToStorage(name, dataLocalStorage);
  };

  updateDependencyManager = async (
    value,
    updateDependency,
    row,
    valueTransformation = (value) => value
  ) => {
    this.setIsLoading(true);
    // Make a function (setLoadings) to let updateDependency manage the loading state
    const setLoadings = (fields, isLoading) => {
      this.setState({ ...this.generateLoadingFields(fields, isLoading) });
    };

    // Make a function (updateFieldValue) to let updateDependency to update the state value
    const updateFieldValue = async (fieldSelector, fetchedValue, isMultiForm = false) => {
      if (isMultiForm) {
        await this.setState({
          [`${fieldSelector}_${row}`]: valueTransformation(fetchedValue)
        });
      } else {
        for (let indField = 0; indField < this.multiInputFields.length; indField++) {
          await this.setState({
            [`${fieldSelector}_${indField}`]: valueTransformation(fetchedValue)
          });
        }
      }
      this.setIsLoading(false);
      await this.updateLocalStorage(this.nameEndPoint);
    };

    // Make a function (updateFetchedFields) to let updateDependency to makage the fetchedFieldsData
    const updateFetchedFields = (fetchedFieldsData) => {
      this.fetchedFields = { ...this.fetchedFields, ...fetchedFieldsData };
    };

    // Returns the row corresponding to the current field
    const getRow = () => row;

    // updateDependency is the function given to trigger an update in another field
    // updateDependency gets:
    //
    // - The current value
    // - Function to get an entity field by the selector
    // - Function setLoadingsFunction
    // - Function updateFetchedFields
    await updateDependency(
      value,
      this.getField(this.props.fields),
      setLoadings,
      updateFetchedFields,
      updateFieldValue,
      this.getFieldValue,
      getRow
    );
    this.setIsLoading(false);
  };

  // Local callback to cast the semanticUI element's onChange returned data
  // into the required data for the given onChange callback
  onValueChange = async (field, value, row) => {
    const { updateDependency } = field;
    const keyName = row ? `${field.selector}_${row}` : `${field.selector}`;
    await this.setState({ [keyName]: value });

    await this.updateLocalStorage(this.nameEndPoint);

    // If this field has an 'updateDependency'
    if (updateDependency) {
      // if there is a timer associated with the execution of 'updateDependencyManager'
      if (field.timerUpdateDependency) {
        this.timers[`${field.selector}_${row}`] = setTimeout(async () => {
          await this.updateDependencyManager(value, updateDependency, row);
          clearTimeout(this.timers[`${field.selector}_${row}`]);
          delete this.timers[`${field.selector}_${row}`];
        }, 2000);
      } else {
        await this.updateDependencyManager(value, updateDependency, row);
      }
    }
  };

  dropDownListOnChange = (field, row) => (event, data) => {
    this.onValueChange(field, data.value, row);
  };
  checkboxOnChange = (field, row) => (event, data) => {
    this.onValueChange(field, data.checked, row);
  };
  photoOnChange = (field, row) => (value) => {
    this.onValueChange(field, value, row);
  };
  datePickerOnChange = (field, row) => (value) => {
    this.onValueChange(field, value, row);
  };
  inputTextOnChange = (field, row) => (input) => {
    const value = field.formatStr ? field.formatStr(input.target.value) : input.target.value;
    this.onValueChange(field, value, row);
  };
  listCheckboxOnChange = (row) => (event, data) => {
    if (row.toString() === 'all') {
      this.allCheckboxSelect = data.checked;
      for (let i = 0; i < this.listCheckbox.length; i++) {
        this.listCheckbox[i].checked = data.checked;
      }
    } else {
      this.listCheckbox[row].checked = data.checked;
    }
    this.checkIfEverythingIsChecked();
    this.forceUpdate();
  };

  checkIfEverythingIsChecked = () => {
    const allChecked = this.listCheckbox.every((item) => item.checked === true);
    if (allChecked) {
      this.allCheckboxSelect = true;
    } else if (!allChecked && this.allCheckboxSelect) {
      this.allCheckboxSelect = false;
    }
  };

  componentDidMount = async () => {
    const { entityToEdit } = this.props;

    if (entityToEdit) {
      this.setState({
        ...this.allowedFields.reduce(
          (obj, field) => ({
            ...obj,
            [field.selector]: field.type !== FieldTypes.Password ? entityToEdit[field.selector] : ''
          }),
          {}
        )
      });
    }

    await this.fetchReferenceFields();
  };

  generateLoadingFields = (fields, isLoading) => {
    fields.reduce((acc, f) => {
      acc[`loading${capitalize(f.selector)}`] = isLoading;
      return acc;
    }, {});
  };

  getField = (fields) => (fieldSelector) => fields.find((f) => f.selector === fieldSelector);
  getFieldValue = (fieldSelector) => this.state[fieldSelector];
  isFieldLoading = (f) => this.state[`loading${capitalize(f.selector)}`];

  // TODO: Should we update 'loadingFields' aftear each fetch?
  fetchReferenceFields = async () => {
    this.setIsLoading(true);
    this.setState({ ...this.generateLoadingFields(this.referenceHeaderFields, true) });
    this.setState({ ...this.generateLoadingFields(this.referenceMultiFields, true) });

    // Fetching all the reference fields
    const fetchedHeaderFieldsArr = await Promise.all(
      this.referenceHeaderFields.map(async (f) => {
        // NOTE: You can set a manual data on the entity definition OR an API source
        const data = f.reference.data
          ? f.reference.data
          : await getEntity(f.reference.endpoint, {
              query: {
                ...f.reference.filter,
                ...(f.reference?.endpointQuery
                  ? f.reference.endpointQuery(this.props.entityToEdit)
                  : {})
              }
            });
        return { [f.selector]: { data } };
      })
    );
    const fetchedMultiFieldsArr = await Promise.all(
      this.referenceMultiFields.map(async (f) => {
        // NOTE: You can set a manual data on the entity definition OR an API source
        const data = f.reference.data
          ? f.reference.data
          : await getEntity(f.reference.endpoint, {
              query: {
                ...f.reference.filter,
                ...(f.reference?.endpointQuery
                  ? f.reference.endpointQuery(this.props.entityToEdit)
                  : {})
              }
            });
        let selectorObj = {};
        for (let i = 0; i < this.multiInputFields.length; i++) {
          selectorObj = { ...selectorObj, [`${f.selector}_${i}`]: { data } };
        }
        return { ...selectorObj };
      })
    );

    const fetchedHeaderFields = fetchedHeaderFieldsArr.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {}
    );
    const fetchedMultiFields = fetchedMultiFieldsArr.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {}
    );
    this.fetchedFields = { ...fetchedHeaderFields, ...fetchedMultiFields };
    this.setState({
      refreshToggle: !this.state.refreshToggle,
      ...this.generateLoadingFields(this.referenceHeaderFields, false),
      ...this.generateLoadingFields(this.referenceMultiFields, false)
    });
    this.multiUpdateDependency(this.multiInputFields.length);
    this.setIsLoading(false);
  };

  onSubmit = (headerFields, multiInputFields) => async (e) => {
    this.setIsLoading(true);

    for (const f of headerFields) {
      if (f.type !== FieldTypes.Boolean && f.required && !this.state[f.selector]) {
        e.preventDefault();
        warningPopAlert(`El campo ${f.name} es requerido`);
        this.setIsLoading(false);
        return;
      }
    }
    const formFields = headerFields.reduce(
      (acc, f) => ({ ...acc, [f.selector]: this.state[f.selector] }),
      {}
    );

    formFields['data'] = [];

    for (let row = 0; row < multiInputFields.length; row++) {
      formFields.data.push({});
      for (const f of multiInputFields[row]) {
        if (f.type !== FieldTypes.Boolean && f.required && !this.state[`${f.selector}_${row}`]) {
          this.setIsLoading(false);
          e.preventDefault();
          warningPopAlert(`El campo ${f.name} de la fila ${row + 1} es requerido`);
          return;
        } else {
          formFields.data[row][`${f.selector}`] = this.state[`${f.selector}_${row}`];
        }
      }
    }

    formFields['massiveLoad'] = false;

    const res = await this.props.onSubmit(e, formFields);

    if (res?.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '¡Documento ingresado!',
        text: `Se registro con éxito el documento con ID: ${res?.data[0]?.idDocumento}`
      });
      removeFromStorage(this.nameEndPoint);
      this.props.toggleModal();
    }

    if (this.props.refetchFieldsOnSubmit) await this.fetchReferenceFields();

    this.setIsLoading(false);
  };

  getFetchedFieldData = (field, row) => {
    const fieldSelector = row ? `${field.selector}_${row}` : `${field.selector}`;

    return this.fetchedFields &&
      this.fetchedFields[fieldSelector] &&
      this.fetchedFields[fieldSelector].data &&
      this.fetchedFields[fieldSelector].data.length > 0
      ? this.fetchedFields[fieldSelector].data
      : [];
  };

  setDefaultValueRow = (fieldInd, lastRowIndex) => {
    const setDefaultValueOnNewRow = this.multiFields[fieldInd].multiInput.setDefaultValueOnNewRow;
    const previusValue = this.state[`${this.multiFields[fieldInd].selector}_${lastRowIndex}`];
    const firstValue = this.state[`${this.multiFields[fieldInd].selector}_0`];

    if (this.multiFields[fieldInd].type === 'date') {
      if (setDefaultValueOnNewRow) {
        this.setState({
          [`${this.multiFields[fieldInd].selector}_${lastRowIndex}`]:
            setDefaultValueOnNewRow.previus ? previusValue : firstValue
        });
      } else {
        this.setState({ [`${this.multiFields[fieldInd].selector}_${lastRowIndex}`]: new Date() });
      }
    }
    if (this.multiFields[fieldInd].type === 'text') {
      if (setDefaultValueOnNewRow) {
        this.setState({
          [`${this.multiFields[fieldInd].selector}_${lastRowIndex}`]:
            setDefaultValueOnNewRow.previus ? previusValue : firstValue
        });
      } else {
        this.setState({ [`${this.multiFields[fieldInd].selector}_${lastRowIndex}`]: '' });
      }
    }
  };

  addMultiInputField = async () => {
    this.multiInputFields.push(this.multiFields);
    this.listCheckbox[this.multiInputFields.length - 1] = {
      name: `chbx_${this.multiInputFields.length - 1}`,
      checked: false
    };
    this.checkIfEverythingIsChecked();
    const lastRowIndex = this.multiInputFields.length - 1;

    for (let fieldInd = 0; fieldInd < this.multiFields.length; fieldInd++) {
      this.setDefaultValueRow(fieldInd, lastRowIndex);
      await this.updateLocalStorage(this.nameEndPoint);
    }
    await this.fetchReferenceFields();
    this.focusFirstField(this.multiInputFields.length - 1);
    this.forceUpdate();
  };

  setIsLoading = (estado) => {
    this.setState({ [`isLoading`]: estado });
    this.forceUpdate();
  };

  initialState = async () => {
    for (let i = 0; i < this.multiFields.length; i++) {
      if (this.multiFields[i].defaultValue) {
        this.state[`${this.multiFields[i].selector}_0`] = this.multiFields[i].defaultValue;
      } else {
        if (this.multiFields[i].type === 'date') {
          this.state[`${this.multiFields[i].selector}_0`] = new Date();
        }
        if (
          this.multiFields[i].type === 'text' ||
          this.multiFields[i].type === FieldTypes.Reference
        ) {
          this.state[`${this.multiFields[i].selector}_0`] = '';
        }
      }
    }
    for (let i = 0; i < this.headerFields.length; i++) {
      const { updateDependency } = this.headerFields[i];
      const value = this.state[this.headerFields[i].selector];
      if (updateDependency) {
        this.updateDependencyManager(value, updateDependency, 0);
      }
    }
    this.setIsLoading(false);
    this.focusFirstField(this.multiInputFields.length - 1);
    await this.updateLocalStorage(this.nameEndPoint);
  };

  clearListCheckbox = () => {
    this.allCheckboxSelect = false;
    this.listCheckbox = [];
    for (let row = 0; row < this.multiInputFields.length; row++) {
      this.listCheckbox[row] = { name: `chbx_${row}`, checked: false };
    }
  };

  clearMultiInput = async () => {
    this.multiInputFields = [];
    this.multiInputFields.push(this.multiFields);
    const keysState = Object.keys(this.state);
    const selectors = this.multiFields.map((item) => item.selector);
    for (const elemento of keysState) {
      for (const selector of selectors) {
        if (elemento.startsWith(selector + '_')) {
          delete this.state[elemento];
          await this.updateLocalStorage(this.nameEndPoint);
        }
      }
    }
    this.initialState();
    this.clearListCheckbox();
    this.forceUpdate();
  };

  multiUpdateDependency = (quantityRows) => {
    const multiUpdateDependencyFields = this.multiFields.filter((f) => f.updateDependency);
    multiUpdateDependencyFields.forEach(async (field) => {
      for (let row = 0; row < quantityRows; row++) {
        const value = this.state[`${field.selector}_${row}`];
        await this.updateDependencyManager(value, field.updateDependency, row);
      }
    });
  };

  duplicateSelected = async (rowToDuplicate) => {
    for (const rowDuplicate of rowToDuplicate) {
      this.multiInputFields.push(this.multiFields);
      this.listCheckbox[this.multiInputFields.length - 1] = {
        name: `chbx_${this.multiInputFields.length - 1}`,
        checked: false
      };
      this.checkIfEverythingIsChecked();
      const lastRowIndex = this.multiInputFields.length - 1;
      for (let fieldInd = 0; fieldInd < this.multiFields.length; fieldInd++) {
        if (this.multiFields[fieldInd].multiInput.isDuplicable) {
          this.setState({
            [`${this.multiFields[fieldInd].selector}_${lastRowIndex}`]:
              this.state[`${this.multiFields[fieldInd].selector}_${rowDuplicate}`]
          });
        } else {
          this.setDefaultValueRow(fieldInd, lastRowIndex);
        }
        this.focusFirstField(lastRowIndex);
        await this.updateLocalStorage(this.nameEndPoint);
        this.forceUpdate();
      }
    }
    // If there are rows and fields with updateDependency, it executes them for each row
    this.multiUpdateDependency(this.multiInputFields.length);

    //await this.updateLocalStorage(this.nameEndPoint);
    await this.fetchReferenceFields();
    this.clearListCheckbox();
    this.forceUpdate();
  };

  removeSelected = async (arrayDelete) => {
    arrayDelete = arrayDelete.sort((a, b) => b - a);
    if (this.allCheckboxSelect) {
      this.clearMultiInput();
    } else {
      for (const filaToDelete of arrayDelete) {
        this.listCheckbox.splice(filaToDelete, 1);
        this.removeMultiInputFields(filaToDelete);
      }
    }
    // If there are rows and fields with updateDependency, it executes them for each row
    this.multiUpdateDependency(this.multiInputFields.length);

    await this.updateLocalStorage(this.nameEndPoint);
    this.clearListCheckbox();
    this.forceUpdate();
  };

  removeMultiInputFields = async (rowDel) => {
    if (rowDel >= this.multiInputFields.length) {
      throw new Error(`Número de fila inválido.(${rowDel})`);
    }
    this.multiInputFields.splice(rowDel, 1);

    for (let i = rowDel; i < this.multiInputFields.length; i++) {
      const currentRow = this.multiInputFields[i];
      const nextRow =
        this.multiInputFields[i + 1] || this.multiInputFields[this.multiInputFields.length - 1];

      for (const item of currentRow) {
        const selector = item.selector;
        const nextSelectorValue = nextRow ? this.state[`${selector}_${i + 1}`] : '';
        this.state[`${selector}_${i}`] = nextSelectorValue;
      }
    }
    if (this.multiInputFields.length > 0) {
      for (const item of this.multiInputFields[this.multiInputFields.length - 1]) {
        const selector = item.selector;
        delete this.state[`${selector}_${this.multiInputFields.length}`];
        await this.updateLocalStorage(this.nameEndPoint);
      }
    } else {
      this.multiInputFields.push(this.multiFields);
    }
    this.focusFirstField(this.multiInputFields.length - 1);
    this.forceUpdate();
  };

  drawFormFields = ({ multi = false, field, rowNumber, key }) => {
    let autoFocus = false;
    const { Text, Email, Rut, Password, Boolean, Reference, Date, Photo, MultiReference } =
      FieldTypes;

    const fieldName = multi ? `${field.selector}_${rowNumber}` : `${field.selector}`;
    const rowValue = multi ? rowNumber : null;

    if (multi === true && !rowNumber) {
      console.log('Multi-field form should receive a row as input, but it got nothing');
      return <p>Si ves este mensaje, por favor contacta con el administrador del sistema</p>;
    }

    return (
      <Fragment key={`generic_form_${key}`}>
        {
          <div className={field.columnClassName || 'col'}>
            {/*TextField*/}
            {(field.type === Text || field.type === Email || field.type === Password) && (
              <FieldInputText
                {...{
                  field,
                  name: `${fieldName}`,
                  onChange: this.inputTextOnChange(field, rowValue),
                  value: this.state[`${fieldName}`] || '',
                  autoFocus: !autoFocus ? (autoFocus = true && true) : false,
                  disabled: field.disabled
                }}
              />
            )}

            {/*RutField*/}
            {field.type === Rut && (
              <FieldInputRut
                {...{
                  field,
                  onChange: this.inputTextOnChange(field, rowValue),
                  value: this.state[`${fieldName}`],
                  autoFocus: !autoFocus ? (autoFocus = true && true) : false,
                  disabled: field.disabled
                }}
              />
            )}

            {/*DatePicker*/}
            {field.type === Date && (
              <div style={{ display: 'block' }}>
                <DatePickerComponent
                  {...{
                    name: `${fieldName}`,
                    onChange: this.datePickerOnChange(field, rowValue),
                    value: this.state[`${fieldName}`],
                    format: field.format,
                    disabled: field.disabled
                  }}
                />
              </div>
            )}

            {/*CheckBox*/}
            {field.type === Boolean && (
              <div style={{ display: 'block' }}>
                <Checkbox
                  onChange={this.checkboxOnChange(field, rowValue)}
                  checked={this.state[`${fieldName}`]}
                  disabled={field.disabled}
                  toggle
                />
              </div>
            )}

            {/*Reference Field (selector)*/}
            {(field.type === Reference || field.type === MultiReference) && (
              <GenericDropDownList
                {...{
                  fieldName: field.name,
                  data: this.getFetchedFieldData(field, rowValue),
                  valueField: field.reference.select,
                  textField: field.reference.show,
                  onChange: this.dropDownListOnChange(field, rowValue),
                  value: this.state[`${fieldName}`],
                  required: field.required,
                  loading: this.state.isLoading,
                  dropDownOptions: {
                    multiple: field.type === MultiReference
                  }
                }}
              />
            )}

            {/*PhotoField*/}
            {field.type === Photo && (
              <div style={{ display: 'block' }}>
                <PhotoHolder
                  constraints={field.constraints}
                  onChange={this.photoOnChange(field, rowValue)}
                  disabled={field.disabled}
                />
              </div>
            )}
          </div>
        }
      </Fragment>
    );
  };

  render() {
    const { actionName } = this.props;
    const renderInputs = [];

    for (const rowNumber in this.multiInputFields) {
      renderInputs.push(
        <div className='row my-3' key={`row my-3_${rowNumber}`}>
          <div className='col-sm-1' style={{ maxWidth: '30px' }} key={`col-sm-1_${rowNumber}`}>
            <Checkbox
              checked={this.listCheckbox[rowNumber].checked}
              onChange={this.listCheckboxOnChange(rowNumber)}
            />
          </div>
          {this.multiInputFields[rowNumber]
            .filter((field) => !field.hidden)
            .map((field, key) => this.drawFormFields({ multi: true, field, rowNumber, key }))}
        </div>
      );
    }

    return (
      <Form onSubmit={this.onSubmit(this.headerFields, this.multiInputFields)}>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='row my-3'>
                {this.headerFields
                  .filter((field) => !field.hidden)
                  .map((field, key) => (
                    <Fragment key={`generic_form_${key}`}>
                      <div className='col'>
                        <Label id={`label_${field.selector}`} for={field.selector}>
                          {`${capitalize(field.name)}${field.required ? ' (*)' : ''}`}
                        </Label>
                      </div>
                    </Fragment>
                  ))}
              </div>
              <div className='row my-3'>
                {this.headerFields
                  .filter((field) => !field.hidden)
                  .map((field, key) => this.drawFormFields({ multi: false, field, key }))}
              </div>
            </div>
          </div>
          {this.listCheckbox.filter((item) => item.checked).length > 0 && (
            <div
              style={{
                backgroundColor: '#64A70B',
                padding: ' 13px',
                color: 'white',
                borderRadius: '8px'
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <div>
                  <label>
                    {this.listCheckbox.filter((item) => item.checked).length} item selected
                  </label>
                </div>
                <div style={{ display: 'flex' }}>
                  <label
                    style={{ marginRight: '1em' }}
                    id={`duplicateBtn`}
                    className='btn btn-primary'
                    onClick={() =>
                      this.duplicateSelected(
                        this.listCheckbox
                          .filter((item) => item.checked)
                          .map((item) => Number(item.name.split('_')[1]))
                      )
                    }>
                    <GenericIcons icon='duplicateWhite' />
                    Duplicar
                  </label>
                  <label
                    id={`delBtn`}
                    className='btn btn-danger'
                    onClick={() =>
                      this.removeSelected(
                        this.listCheckbox
                          .filter((item) => item.checked)
                          .map((item) => Number(item.name.split('_')[1]))
                      )
                    }>
                    <GenericIcons icon='trashWhite' />
                    Eliminar
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-sm-12'>
              <div className='row my-3'>
                <div className='col-sm-1' style={{ maxWidth: '30px' }}>
                  <Checkbox
                    checked={this.allCheckboxSelect}
                    onChange={this.listCheckboxOnChange('all')}
                  />
                </div>
                {this.multiFields
                  .filter((field) => !field.hidden)
                  .map((field, key) => (
                    <Fragment key={`generic_form_${key}`}>
                      {
                        <div className={field.columnClassName || 'col'}>
                          <Label id={`label_${field.selector}`} for={field.selector}>
                            {`${capitalize(field.name)}${field.required ? ' (*)' : ''}`}
                          </Label>
                        </div>
                      }
                    </Fragment>
                  ))}
              </div>
              {renderInputs}
            </div>

            {this.state.isLoading === true && <h2>Cargando...</h2>}

            <div className='row'>
              <div className='col-sm-12'>
                <label
                  id={`addBtn`}
                  className='btn btn-outline-success'
                  disabled={this.state.isLoading}
                  onClick={() => this.addMultiInputField()}>
                  Añadir nuevo
                </label>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12'>
                <Button
                  color='dark'
                  style={{ marginTop: '2rem' }}
                  disabled={this.state.isLoading}
                  block>
                  {actionName || `Enviar Datos`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

GenericMultiInputForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  refetchFieldsOnSubmit: PropTypes.bool,
  actionName: PropTypes.string,
  isAdmin: PropTypes.bool
};
