import React, { FunctionComponent, useEffect, useState } from 'react';
import InputField from './InputField';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectInput, { SelectDataType } from './SelectInput';
import Alert from '@mui/material/Alert';

const TrazabilidadMultiForm: FunctionComponent<{
  sku: Array<{
    id: string;
    name: string;
    description: string;
  }>;
  isLoading: boolean;
  handleChangeSelectedSku: (
    selectedSku: Array<{
      sku: string;
      cantidad: string;
    }>
  ) => void;
}> = ({ sku, isLoading, handleChangeSelectedSku }) => {
  const [selectedSku, setSelectedSku] = useState<
    Array<{
      sku: SelectDataType | null;
      cantidad: string;
      descripcion: string;
      error?: boolean;
    }>
  >([{ sku: null, cantidad: '', descripcion: '' }]);

  useEffect(
    () =>
      handleChangeSelectedSku(
        selectedSku.map((d) => ({ sku: (d.sku?.value || '').toString(), cantidad: d.cantidad }))
      ),
    [selectedSku]
  );

  useEffect(() => {
    setSelectedSku([{ sku: null, cantidad: '', descripcion: '' }]);
  }, [sku]);

  const handleAddRow = () => {
    setSelectedSku([...selectedSku, { sku: null, cantidad: '', descripcion: '' }]);
  };

  const handleRemoveRow = (index: number) => {
    if (selectedSku.length > 1) setSelectedSku(selectedSku.filter((_, i) => i !== index));
  };

  const handleSkuChange = async (index: number, value: SelectDataType | null) => {
    const updatedData = [...selectedSku];

    updatedData[index].error = false;

    const alreadySelected = selectedSku.find(
      (d, idx) => idx !== index && value?.value && d.sku?.value === value?.value
    );

    if (alreadySelected) {
      updatedData[index].sku = null;
      updatedData[index].descripcion = '';
      updatedData[index].cantidad = '';
      updatedData[index].error = true;
      setSelectedSku(updatedData);
      return;
    }

    updatedData[index].sku = value;

    const findSku = sku.find((s) => s.id === value?.value);
    const description = findSku?.description || '';

    updatedData[index].descripcion = description;
    updatedData[index].cantidad = '';

    setSelectedSku(updatedData);
  };

  const handleCantidadChange = (index: number, value: string) => {
    const updatedData = [...selectedSku];
    updatedData[index].cantidad = value;
    setSelectedSku(updatedData);
  };

  return (
    <div className='trazabilidad-multi-form'>
      <div>
        <Button
          variant='contained'
          color='success'
          onClick={() => handleAddRow()}
          disabled={isLoading}>
          Nueva fila
        </Button>
      </div>

      {selectedSku.map((d, idx) => (
        <div key={idx} className='trazabilidad-multi-form-row'>
          {d.error && (
            <div className='trazabilidad-multi-form-row-error'>
              <Alert severity='error'>El SKU ingresado ya existe en otra fila</Alert>
            </div>
          )}

          <div className='trazabilidad-multi-form-row-fields-container'>
            <SelectInput
              label='SKU'
              value={d.sku}
              onChange={(_, newValue) => {
                handleSkuChange(idx, newValue);
              }}
              options={sku.map((s) => ({ name: s.name, value: s.id }))}
              error={d.error}
              disabled={isLoading}
              required
            />

            <InputField
              label='Descripción'
              value={d.descripcion}
              variant='standard'
              disabled={isLoading}
              readOnly
              fullWidth
            />
            <InputField
              label='Cantidad'
              value={d.cantidad}
              type='number'
              handleInputChange={(e) => {
                const newValue = e.target.value;
                if (/^\d*$/.test(newValue)) {
                  // integers only
                  handleCantidadChange(idx, e.target.value);
                }
              }}
              disabled={isLoading || !d.sku}
              minValue={1}
              fullWidth
              required
            />
          </div>
          <div className='trazabilidad-multi-form-row-action-container'>
            <div>
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => handleRemoveRow(idx)}
                disabled={isLoading}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrazabilidadMultiForm;
