import moment from 'moment';
import qs from 'qs';
import { SimpleObject } from '../types';

export const MULTI_FIELD_TOKEN = '[x]';

export const spanishDayNames = [
  'Domingo',
  'Lunes',
  'Martes',
  'miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
];

export const spanishMonthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

// ENTITIES utilities
export const dateFormatFn = (f: string, format = 'DD/MM/YYYY HH:mm:ss', utc = false) =>
  f ? (utc ? moment(f).utc().format(format) : moment(f).local().format(format)) : '';

export const booleanFormatFn = (f: any) =>
  `${f === null || f === undefined ? '' : f ? 'SI' : 'NO'}`;

// Util to check if object is empty
export const isObjectEmpty = (obj: object) =>
  !!(obj && typeof obj === 'object' && Object.keys(obj).length === 0);

// Capitalize text
export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str)
    .trim()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

// Camelize a phrase. Upper the first char after a dot.
export const camelizePhrase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .split('.')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join('.');

// Transform object into a get url parameters
export const objectToQueryString = (obj: object, prefix = '?') =>
  !isObjectEmpty(obj) ? prefix + qs.stringify(obj) : '';

// Clean object before to transform it into a get url parameters
const filterFromObjectByValue = (obj: any, value = '') =>
  !isObjectEmpty(obj)
    ? Object.keys(obj).reduce((acc, k) => (obj[k] !== value ? { ...acc, [k]: obj[k] } : acc), {})
    : {};

export const filterFromObjectByKeys = (obj: any, keys: Array<string> = []) =>
  !isObjectEmpty(obj)
    ? Object.keys(obj).reduce((acc, k) => (!keys.includes(k) ? { ...acc, [k]: obj[k] } : acc), {})
    : {};

// Merge objects
export const mergeValidValuesFromObjects = (objectA: SimpleObject, objectB: SimpleObject) =>
  Object.assign(
    {},
    objectA,
    Object.fromEntries(
      Object.entries(objectB).filter(([_, v]) => v !== null && v !== undefined && v !== '')
    )
  );

export const filterObject = (obj: SimpleObject, keysToPick: Array<string>) =>
  obj
    ? Object.keys(obj).reduce((acc, key) => {
        if (keysToPick.includes(key)) {
          acc[key] = obj[key];
        }
        return acc;
      }, {} as SimpleObject)
    : {};

export const simpleTextDateFormat = (textDate: string): string => {
  return (textDate || '').trim().replace(/\./g, '/').replace(/-/g, '/');
};

export const cleanDate = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
};

export const cleanTextDate = (date: Date | null) => {
  if (date !== null) {
    return simpleTextDateFormat(cleanDate(date).toISOString().split('T')[0]);
  }
  return null;
};

export const formatStringNumber = (
  numberString: string,
  minimumFloat = 0,
  maximumFloat = 4,
  groupingSeparator = '.',
  decimalSeparator = ','
): string => {
  const numero = parseFloat(numberString);

  if (isNaN(numero)) {
    return 'Invalid';
  }
  const numeroRedondeado = numero.toFixed(maximumFloat);
  const parts = numeroRedondeado.split('.');
  let formattedIntegerPart = parts[0];
  let formattedFloatPart = parts.length > 1 ? parts[1] : '';

  if (groupingSeparator !== '') {
    formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, groupingSeparator);
  }

  if (formattedFloatPart.length < minimumFloat) {
    formattedFloatPart += '0'.repeat(minimumFloat - formattedFloatPart.length);
  }

  formattedFloatPart = formattedFloatPart.replace(/0+$/, '');

  let res = formattedIntegerPart;
  if (formattedFloatPart !== '' && parseInt(formattedFloatPart) !== 0) {
    res += decimalSeparator + formattedFloatPart;
  }
  return res;
};

// Aproxima los decimales SIEMPRE al numero ENTERO siguiente
// ej: 23,02 -> 24
// ej: 23,99 -> 24
export const specialFormatStringNumber = (
  numberString: string | number,
  float = false,
  minimumFloat = 0,
  maximumFloat = 4,
  groupingSeparator = '.'
) => {
  const numberStringToFormat =
    typeof numberString === 'number' ? numberString.toString() : numberString;

  if (!numberStringToFormat) {
    return '';
  }

  let roundedNumber = float ? parseFloat(numberStringToFormat) : parseInt(numberStringToFormat);

  if (float) {
    const multiplier = 10 ** maximumFloat;
    roundedNumber = Math.ceil(roundedNumber * multiplier) / multiplier;
  }

  return roundedNumber.toLocaleString('es-CL', {
    useGrouping: true, // utiliza separador de miles
    minimumFractionDigits: float ? minimumFloat : 0,
    maximumFractionDigits: float ? maximumFloat : 0,
    minimumIntegerDigits: 1, // muestra al menos un dígito entero
    groupingSeparator: groupingSeparator, // utiliza punto como separador de miles
    decimalSeparator: ',' // utiliza coma como separador decimal
  } as Intl.NumberFormatOptions);
};

export const cleanObjectToQueryString = (obj: any, prefix = '?') =>
  objectToQueryString(filterFromObjectByValue(obj, ''), prefix);

// Sub-Field selector
export const fieldSelector = (selector: string | ((obj: object) => any)) => (obj: object) => {
  // If its a functions, will use the "format" function from the Entity
  if (typeof selector === 'function') {
    return selector(obj);
  }

  // Otherwhise we assume that the selector is the string field from the Entity
  if (!obj) {
    return 'Sin Datos';
  }

  if (typeof obj === 'string') {
    return obj;
  }

  const path = selector.split('.');
  return path.reduce((currField: any, key) => (currField ? currField[key] : 'Sin Datos'), obj);
};

// Multi-Sub-Field selector
export const multiSubFieldSelector = (selector: string, obj: any): any => {
  const fieldSeparator = '.';

  if (!obj) return 'Sin Datos';
  if (typeof obj === 'string') return obj;

  const firstSeparator = selector.indexOf(fieldSeparator);

  // Get the last selector (no more fieldSeparator)
  if (firstSeparator === -1 && selector) return obj[selector] || 'Sin Datos';

  const currentFieldName = selector.substring(0, firstSeparator);

  // In case cannot find the selected item (Ignore if 'currentFieldName' is 'MULTI_FIELD_TOKEN')
  if (currentFieldName !== MULTI_FIELD_TOKEN && obj[currentFieldName] === undefined)
    return 'Sin Datos';

  // Recursive call to get all the array elements fields
  if (currentFieldName === MULTI_FIELD_TOKEN) {
    if (!Array.isArray(obj)) {
      return 'Error: Se esperaba un array';
    }
    return obj.map((element) =>
      multiSubFieldSelector(selector.substring(selector.indexOf(fieldSeparator) + 1), element)
    );
  }

  if (obj[currentFieldName]) {
    // Recursive call to chomp each node of the path until reach the target
    return multiSubFieldSelector(selector.substring(firstSeparator + 1), obj[currentFieldName]);
  }
};

// Make sure to return the query object
export const loadQuery = async (query: object | (() => Promise<any>) | undefined) =>
  typeof query === 'function' ? await query() : query;

// Fusion fields
export const fusionFields = (pathFields: string) => (obj: object) => {
  if (!obj || isObjectEmpty(obj)) return 'Sin Datos';
  const fields = pathFields.split(',').map((f) => f.trim());
  return joinColumns(obj, fields);
};

// Filters
const AND_TOKEN_FILTER = '&';
const OR_TOKEN_FILTER = '|';
const NO_TOKEN_FILTER = '';

// Generic Table static data and function
const joinColumns = (obj: any, columns: any) =>
  columns.reduce(
    (acc: any, column: any) =>
      acc ? `${acc} ${fieldSelector(column)(obj)}` : fieldSelector(column)(obj),
    ''
  );

// Normal filter
const normalFilter = (data: any, columns: any, filter: any) =>
  data.filter((item: any) =>
    joinColumns(item, columns).toLowerCase().includes(filter.toLowerCase())
  );

// And filter
const andFilter = (data: any, columns: any, filters: any) =>
  filters.reduce(
    (filteredData: any, currentFilter: any) => normalFilter(filteredData, columns, currentFilter),
    data
  );

// Or filter
const orFilter = (data: any, columns: any, filters: any) => {
  const validFilters = filters.filter((f: any) => f);
  if (!validFilters.length) return data;

  return validFilters.reduce((filteredData: any, currentFilter: any) => {
    const currentData =
      validFilters[0] === currentFilter ? [] : normalFilter(data, columns, currentFilter);

    return [...filteredData, ...currentData];
  }, normalFilter(data, columns, validFilters[0]));
};

// Auto detect filter
export const specialFilter = (data: any, columns: any, filterText: any) => {
  if (!filterText) return data;

  let detectedFilter =
    // if
    filterText.includes(AND_TOKEN_FILTER)
      ? AND_TOKEN_FILTER
      : // else if
      filterText.includes(OR_TOKEN_FILTER)
      ? OR_TOKEN_FILTER
      : // else
        NO_TOKEN_FILTER;

  switch (detectedFilter) {
    case AND_TOKEN_FILTER:
      return andFilter(data, columns, filterText.split(AND_TOKEN_FILTER));
    case OR_TOKEN_FILTER:
      return orFilter(data, columns, filterText.split(OR_TOKEN_FILTER));
    default:
      return normalFilter(data, columns, filterText);
  }
};

export const modificarOrdenColumnas = (data: string, columnsConteo: Record<string, string>) => {
  const filas = data.split('\n');
  const columnasOriginales = filas[0].split(';');
  const nuevasColumnas = Object.values(columnsConteo);

  const lastData = filas.pop();

  const isLastRowEmpty = lastData === '' || /^\s+$/g.test(lastData as string);

  let csvModificado = filas
    .map((fila, indice) => {
      if (indice === 0) {
        return nuevasColumnas.join(';');
      } else {
        const valores: string[] = fila.split(';');
        const nuevaFila: string[] = nuevasColumnas.map((nombreColumna) => {
          const columnaOriginal: string | undefined = Object.keys(columnsConteo).find(
            (key) => columnsConteo[key] === nombreColumna
          );
          const indiceColumnaOriginal: number =
            columnaOriginal !== undefined ? columnasOriginales.indexOf(columnaOriginal) : -1;
          const value = valores[indiceColumnaOriginal];
          return indiceColumnaOriginal !== -1 ? value : '';
        });
        return nuevaFila.join(';');
      }
    })
    .join('\n');

  if (isLastRowEmpty) {
    csvModificado += '\n' + lastData;
  }

  return new Blob([csvModificado], { type: 'text/csv' });
};
