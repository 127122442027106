import { FieldTypes } from '../../entities/types';
import { Transportista } from '../../types';
import { booleanFormatFn, dateFormatFn } from '../../utils/utils';
import { WebEntity } from './types';

const TransportistaEntity: WebEntity<Transportista> = {
  name: 'transportista',
  endpoint: 'usuarioTransportista/transportistas',
  referenceColumn: '_id',
  customDataActions: {
    editModalTitle: 'Editar EETT'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Nombre',
      accessorKey: 'nombreTransportista',
      columnType: 'text'
    },
    {
      header: 'Rut',
      accessorKey: 'rutTransportista',
      columnType: 'text'
    },
    {
      header: 'BP',
      accessorKey: 'bp',
      columnType: 'text'
    },
    {
      header: 'Activo',
      accessorKey: 'activo',
      columnType: 'boolean',
      Cell: ({ row }) => booleanFormatFn(row.original.activo)
    },
    {
      header: 'Actualizado el',
      accessorKey: 'fechaActualizacion',
      columnType: 'dateTime',
      Cell: ({ row }) => dateFormatFn(row.original.fechaActualizacion)
    },
    {
      header: 'Actualizado por',
      accessorKey: 'nombreUsuario',
      columnType: 'text'
    }
  ],
  editableFields: [
    {
      name: 'Activo',
      selector: 'activo',
      type: FieldTypes.Boolean,
      required: true
    }
  ]
};

export default TransportistaEntity;
