import { ZonasOperador } from '../types';
import { FieldTypes, WebEntity } from './types';

const ZonasOperadorEntity: WebEntity<ZonasOperador> = {
  name: 'zonasOperador',
  endpoint: 'centroInventario/zonasOperador',
  referenceColumn: 'nombreUsuario',

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omit: true
    },
    {
      name: 'Nombre',
      id: 'nombreUsuario',
      selector: (row) => row.nombreUsuario,
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Bodegas Asignadas',
      id: 'zonasAsignadas',
      selector: (row) => row.zonasAsignadas,
      columnType: 'text',
      wrap: true,
      format: ({ zonasAsignadas }) => zonasAsignadas?.toString() || ''
    }
  ],

  fields: [
    {
      name: 'Operador',
      selector: 'idUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'usuario',
        endpoint: 'usuarios',
        select: '_id',
        show: 'nombreUsuario',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Bodegas Asignadas',
      selector: 'zonasAsignadas',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'zonas',
        endpoint: 'centroInventario/zonas/all',
        select: 'nombreCentro',
        show: 'nombreCentro'
      }
    }
  ],

  editableFields: [
    {
      name: 'Operador',
      selector: 'idUsuario',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'usuario',
        endpoint: 'usuarios',
        select: '_id',
        show: 'nombreUsuario'
      }
    },
    {
      name: 'Bodegas Asignadas',
      selector: 'zonasAsignadas',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'zonas',
        endpoint: 'centroInventario/zonas/all',
        select: 'nombreCentro',
        show: 'nombreCentro'
      }
    }
  ]
};

export default ZonasOperadorEntity;
