import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import {
  UsuarioCRUD,
  UsuarioTransportistaCRUD,
  TransportistasCRUD,
  TipoCausalCRUD
} from '../new-table/components/GeneratedComponents';
import BackButton from '../new-table/components/BackButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Alert from '@mui/material/Alert';

const TrazabilidadAdmin: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const [value, setValue] = useState<string>('1');
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className='trazabilidad-container'>
      <div className='trazabilidad-title-container'>
        <BackButton route='/cobros' />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Relacion Usuario-Transportista' value='1' />
            <Tab label='Usuarios' value='2' />
            <Tab label='Empresas Transportistas' value='3' />
            <Tab label='Tipo Causal' value='4' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <Alert severity='info'>
            Asocia usuarios de tipo TRANSPORTISTA a una empresa transportista.
          </Alert>
          <UsuarioTransportistaCRUD />
        </TabPanel>
        <TabPanel value='2'>
          <Alert severity='info'>Como ADMINISTRADOR COBROS puedes crear nuevos usuarios.</Alert>
          <UsuarioCRUD />
        </TabPanel>
        <TabPanel value='3'>
          <Alert severity='info'>
            Al desactivar una empresa transportista, se eliminarán las relaciones de todos los
            usuarios asignados a ella. Además, la empresa dejará de ser elegible para la creación de
            nuevos cobros.
          </Alert>
          <TransportistasCRUD />
        </TabPanel>
        <TabPanel value='4'>
          <Alert severity='info'>
            Causales disponibles para el formulario de agregar OCA (Merma)
          </Alert>
          <TipoCausalCRUD />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const TrazabilidadAdminConnected = connect(
  ({ auth }: AppState) => ({ auth }),
  null
)(TrazabilidadAdmin);
