import { FieldTypes } from '../../entities/types';
import { TipoCausal } from '../../types';
import { WebEntity } from './types';

const TipoCaulsaEntity: WebEntity<TipoCausal> = {
  name: 'tipoCausal',
  endpoint: 'tipoCausal',
  referenceColumn: '_id',
  customDataActions: {
    editModalTitle: 'Editar causal',
    addModalTitle: 'Agregar causal'
  },
  customButtonNames: {
    addButtonName: 'Agregar causal'
  },
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'Nombre causal',
      accessorKey: 'nombreCausal',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Nombre causal',
      selector: 'nombreCausal',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Nombre causal',
      selector: 'nombreCausal',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default TipoCaulsaEntity;
