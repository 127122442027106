import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CSVLink } from 'react-csv';
import DataTable, { createTheme } from 'react-data-table-component';
import { Alert, Button, Input, InputGroup } from 'reactstrap';
import { Icon } from 'semantic-ui-react';
import { ColumnType, FilterFields, TableColumn } from '../../entities/types';
import { BasicEntity, SimpleObject } from '../../types';
import { convertArrayOfObjectToFieldsMatrix, downloadXLSX } from '../../utils/export';
import { getObjectFromStorage, addObjectToStorage, removeFromStorage } from '../../utils/storage';
import { specialFilter, filterObject } from '../../utils/utils';
import Pagination from '../Pagination';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  ColumnComponent,
  RowDisabledCriteria
} from '../types';
import { GenericFilterFormModal } from './GenericFilterForm';
import { customStyles } from './GenericThemes';
import { warningPopAlert } from '../PopAlert';

createTheme('invertedHeader', {
  context: {
    background: '#23272b',
    text: '#F8F8EB'
  }
});

const EmptyTableMessage: FunctionComponent = () => (
  <div style={{ marginTop: 10 }}>
    <Alert color='warning' fade={false}>
      ¡No existen registros por el momento!
    </Alert>
  </div>
);

const FilterComponent: FunctionComponent<{
  filterText: string;
  onChange: (e: any) => void;
  onKeyDown: (e: any) => void;
  onClear: () => void;
}> = ({ filterText, onChange, onKeyDown, onClear }) => (
  <InputGroup>
    <Input
      id='search'
      type='text'
      placeholder='Filtro general'
      value={filterText}
      onKeyDown={onKeyDown}
      onChange={onChange}
    />
    <Button onClick={onClear}>&times;</Button>
  </InputGroup>
);

const CustomExport: FunctionComponent<{
  exportDataHandler: (when?: string) => Promise<any[]>;
  columnTypes: ColumnType[];
  fileName?: string;
}> = ({ exportDataHandler, columnTypes, fileName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const exportData = async () => {
    setLoading(true);

    const data = await exportDataHandler();

    if (data.length === 0) {
      setLoading(false);
      return warningPopAlert(`No se encontraron registros que exportar`);
    }

    downloadXLSX(data, columnTypes, fileName);

    setLoading(false);
    return;
  };

  return (
    <>
      {loading && <h1>Cargando...</h1>}
      <button
        onClick={exportData}
        style={{
          display: 'flex',
          padding: '5px 15px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: 'transparent',
          borderRadius: '8px',
          border: '2px solid #007DBA',
          color: '#007DBA',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px'
        }}>
        Exportar Excel
      </button>
    </>
  );
};

const StoredFormFilterManager: FunctionComponent<{
  storedFilterName: string;
  storedFormFilter: SimpleObject;
  refreshFunction: any;
}> = ({ storedFilterName, storedFormFilter, refreshFunction }) => {
  const objectToData = (simpleObject: SimpleObject) => ({
    header: ['filtro', 'valor'],
    rows: simpleObject
      ? Object.entries(simpleObject).filter(([_, v]) => v != null && v !== '')
      : [[]]
  });

  const data = objectToData(storedFormFilter);
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(objectToData(storedFormFilter));
  }, [storedFormFilter]);

  const onCleanStorageSingleItem = (itemName: string) => {
    const data = getObjectFromStorage(storedFilterName);
    removeFromStorage(storedFilterName);

    delete data[itemName];
    addObjectToStorage(storedFilterName, data);
    refreshFunction();
  };

  return (
    <Fragment>
      {tableData.rows && tableData.rows[0]?.length > 0 && (
        <Fragment>
          {tableData.rows.map((item, i) => {
            const filterName = item[0];
            const filterValue =
              item[1] instanceof Date ? new Date(item[1]).toLocaleDateString() : item[1];
            return (
              <button
                key={i}
                style={{
                  display: 'flex',
                  height: '34px',
                  padding: '2px 10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                  backgroundColor: 'transparent',
                  borderRadius: '8px',
                  border: '2px solid var(--ccu-verde-oscuro, #205C40)'
                }}
                onClick={() => onCleanStorageSingleItem(filterName)}>
                <p
                  style={{
                    color: 'var(--ccu-verde-oscuro, #205C40)',
                    textAlign: 'center',
                    fontFamily: ' Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}>
                  {filterValue}
                </p>
                X
              </button>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};

export const ExportCSV: FunctionComponent<{ onExport: () => string[][] }> = ({ onExport }) => (
  <CSVLink
    style={{
      display: 'inline-block',
      padding: '10px 20px',
      marginInline: '1em',
      fontSize: '16px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#4CAF50',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer'
    }}
    data={onExport()}
    filename={`export_${new Date().toISOString().split('T')[0]}.csv`}
    separator={';'}>
    <Icon name={'download'} />
  </CSVLink>
);

const Export: FunctionComponent<{
  onExport: () => any[][];
  columnTypes: ColumnType[];
}> = ({ onExport, columnTypes }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);

    setTimeout(() => {
      const data = onExport();

      downloadXLSX(data, columnTypes);

      setLoading(false);
    }, 500);
  };

  return (
    <>
      {loading && <h1>Cargando...</h1>}
      <button
        onClick={handleExport}
        style={{
          display: 'flex',
          padding: '5px 15px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: 'transparent',
          borderRadius: '8px',
          border: '2px solid #007DBA',
          color: '#007DBA',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px'
        }}>
        Exportar Excel
      </button>
    </>
  );
};

// Rows per page in server side
// TODO: change this once the endpoint let us set 'ROWS_PER_PAGE' from client side
const ROWS_PER_PAGE = 20;
const DEFAULT_PAGE = 1;

type Props<T> = {
  columns: TableColumn<T>[];
  tableData: BasicEntity[];
  fetchData: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  filterFields?: FilterFields<T>[];
  addAction?: JSX.Element;
  addMultiLineAction?: JSX.Element;
  deleteAction?: () => (getSelectedData: () => { ids: string[]; refs: string[] }) => JSX.Element;
  secondaryAction?: AdditionalTableActionsFunc<T>;
  allowedActions?: Partial<{
    add: boolean;
    multiLineForm: boolean;
    delete: boolean;
    export: boolean;
    select: boolean;
    singleSelect: boolean;
  }>;
  tableName?: string;
  serverSidePagination?: boolean;
  onSelectedRowsChange?: (rows: T[]) => void;
  liveFilter?: boolean;
  sharedFilterName?: string;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  selectablePageOnly?: boolean;
  rowDisabledCriteria?: RowDisabledCriteria<T>;
  rowColumnReference?: string;
  columnComponent?: ColumnComponent<T>;
  additionalTableProps?: AdditionalTableProps<T>;
  customExport?: {
    exportDataHandler: (when?: string) => Promise<any[]>;
    columnTypes: ColumnType[];
    fileName?: string;
  };
  selectedSingleEntity?: <T extends BasicEntity>(row: T, event: React.MouseEvent) => any;
  isAdmin?: boolean;
  forceRefresh?: boolean;
  ViewInput?: boolean;
};

const GenericTable = <T,>({
  // Required
  columns,
  tableData,
  fetchData,
  // Optionals
  fixedHeaderScrollHeight,
  fixedHeader = false,
  filterFields,
  addAction,
  addMultiLineAction,
  deleteAction,
  secondaryAction,
  allowedActions,
  tableName = '',
  serverSidePagination = false,
  onSelectedRowsChange,
  liveFilter = false,
  sharedFilterName,
  selectablePageOnly = false,
  rowDisabledCriteria,
  rowColumnReference = '',
  columnComponent,
  additionalTableProps,
  customExport,
  selectedSingleEntity,
  isAdmin,
  forceRefresh,
  ViewInput = true
}: Props<T>) => {
  // === Initialize states and its 'setters functions' sub-components ===
  // NOTE: useState(initialValue) returns an 'state' var and an asociated 'setter' function
  // FilterText text

  const [filterText, setFilterText] = useState<string>('');
  const [preFilterText, setPreFilterText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);

  // Pagination state
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  // Server side pagination
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{ totalRows: number; availablePages: number }>();

  // Server side filter
  const getStoredSharedFilter = () => {
    if (filterFields) {
      const validFilters = filterFields.map((ff) => ff.selector);
      const sharedFilter = getObjectFromStorage(sharedFilterName || '');
      return filterObject(sharedFilter, validFilters);
    }
    return null;
  };

  const sharedFilter = getStoredSharedFilter();
  // TODO: Add only the filters that match filterfields
  const [formFilters, setFormFilters] = useState(sharedFilter || {});

  // Selected rows
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //Selected IDEntity
  const [idEntity, setIdEntity] = useState('');
  const columnsSelector = useMemo(
    () => columns.map((c) => (c.format ? c.format : c.selector)),
    [columns]
  );
  const {
    add: canAdd,
    multiLineForm: canMultiLine,
    delete: canDelete,
    export: canExport,
    select: canSelect,
    singleSelect
  } = {
    add: true,
    multiLineForm: true,
    delete: true,
    export: false,
    select: false,
    singleSelect: false,
    ...allowedActions
  };

  const tableColumns = useMemo(
    () =>
      columnComponent
        ? columnComponent.begin
          ? [{ ...columnComponent.column }, ...columns]
          : [...columns, { ...columnComponent.column }]
        : columns,
    [columns, columnComponent, tableData]
  );

  const fetchDataPage = useMemo(
    () => async (currentPage: number) => {
      setLoading(true);

      if (serverSidePagination) {
        const { availablePages, totalItemsCount } = await fetchData(
          currentPage,
          pageSize,
          formFilters
        );
        setPagination({ totalRows: totalItemsCount, availablePages });
      } else {
        await fetchData(0, 0, formFilters);
      }

      setLoading(false);
    },
    [fetchData, serverSidePagination, formFilters, pageSize, forceRefresh]
  );

  const onChangeRowsPerPage = (currentRowsPerPage: number) => {
    setPageSize(currentRowsPerPage);
  };

  // ComponentDidUpdate (fetchDataPage)
  // After changing rows per page, always refresh on page 1
  useEffect(() => {
    fetchDataPage(1);
  }, [fetchDataPage]);

  // ComponentDidUpdate (dataTable)
  useEffect(() => {
    setToggleCleared((toggleCleared) => !toggleCleared);
  }, [tableData]);

  // ComponentDidUpdate (fetch data applying the new filters)
  useEffect(() => {
    // Reset pagination
    setResetPaginationToggle((resetPaginationToggle) => !resetPaginationToggle);
    // Fetch data
    fetchDataPage(DEFAULT_PAGE);
  }, [formFilters, fetchDataPage]);
  // Filter items by checking if filterText is contained into the item's joined fields
  const filteredItems = useMemo(
    () => specialFilter(tableData, columnsSelector, filterText),
    [tableData, columnsSelector, filterText]
  );

  // Fn = Create a callback for row selection
  const handleRowSelected = useCallback(
    (state) => {
      setSelectedRows(state.selectedRows);
      onSelectedRowsChange!(state.selectedRows);
    },
    [onSelectedRowsChange]
  );

  // Fn = Create filter component for subheader
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText || preFilterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setPreFilterText('');
        setFilterText('');
      }
    };

    const onChange = (e: any) => {
      setPreFilterText(e.target.value);
      if (liveFilter) setFilterText(e.target.value);
    };

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        setPreFilterText(e.target.value);
        setFilterText(e.target.value);
      }
    };

    return (
      <FilterComponent
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClear={handleClear}
        filterText={preFilterText}
      />
    );
  }, [filterText, preFilterText, liveFilter, resetPaginationToggle]);

  const refreshStoredFilter = () => {
    const sharedFilter = getStoredSharedFilter();
    setFormFilters(sharedFilter || {});
  };

  const exportColumns = useMemo(() => columns.filter((obj) => !obj.omitExport), [columns]);

  const renderActions = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {/* TODO: temporary Change, add again 'serverSidePagination &&' at the begining of the IF */}
      <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: ' center' }}>
        {filterFields && filterFields.length > 0 && (
          <GenericFilterFormModal
            filterFormFields={filterFields}
            onSubmit={applyServerSideFilter}
            isAdmin={!!isAdmin}
          />
        )}
        {sharedFilterName && (
          <StoredFormFilterManager
            storedFilterName={sharedFilterName}
            storedFormFilter={formFilters}
            refreshFunction={refreshStoredFilter}
          />
        )}
      </div>

      <div style={{ display: 'flex', gap: ' 10px ' }}>
        {canExport ? (
          customExport ? (
            <CustomExport
              exportDataHandler={customExport.exportDataHandler}
              columnTypes={customExport.columnTypes}
              fileName={customExport.fileName}
            />
          ) : (
            <Export
              onExport={() =>
                convertArrayOfObjectToFieldsMatrix(
                  filteredItems.map((row: any) =>
                    exportColumns.reduce((acc, obj) => {
                      if (obj.id && row.hasOwnProperty(obj.id)) {
                        const key = obj.id as string;
                        acc[key] = obj.valueToExport ? obj.valueToExport(row) : row[key];
                      }
                      return acc;
                    }, {} as Record<string, any>)
                  ),
                  exportColumns.map((c: any) => c.selector),
                  exportColumns.map((c: any) => c.name)
                )
              }
              columnTypes={exportColumns.map((obj) => obj.columnType)}
            />
          )
        ) : null}
        <div>{canMultiLine && addMultiLineAction}</div>

        {canAdd && addAction}
      </div>
    </div>
  );

  // Fn = create a component to delete selected fields
  const contextActions = useMemo(() => {
    const getSelectedData = () => {
      const idColumn = '_id';

      // Show the idColumn if the rowColumnReference was not provided
      const rcr = rowColumnReference || idColumn;

      // Function to select the data to show (depending on if it has data)
      const selectRowColumnFn = (r: any) => r[rcr] || r[idColumn];

      // Getting the rows id (to work on it)
      // Getting the rows reference column (to show as message)
      const { refs, ids } = selectedRows.reduce(
        (prev, curr) => ({
          refs: [...prev.refs, selectRowColumnFn(curr)],
          ids: [...prev.ids, curr[idColumn]]
        }),
        { refs: [], ids: [] } as { refs: string[]; ids: string[] }
      );

      return { ids, refs };
    };
    return (
      <div style={{ display: 'flex' }}>
        {secondaryAction && secondaryAction({ selected: selectedRows })}
        {canDelete && deleteAction && deleteAction()(getSelectedData)}
        {/* TODO: Check this file in 6e6d967f0c90f7c8c45609174791595ae8950c46
         * REALLY STRANGE ERROR:
         * If I execute deleteAction={this.deleteButton()} in GenericCRUD and then
         * deleteAction(getSelectedData) in here, the 'secondaryAction' button cannot
         * refresh its own state.
         * */}
      </div>
    );
  }, [selectedRows, deleteAction, canDelete, rowColumnReference, secondaryAction]);

  const serverSidePaginationOptions = serverSidePagination
    ? {
        paginationTotalRows: pagination?.totalRows,
        paginationServer: true,
        onChangePage: fetchDataPage,
        onChangeRowsPerPage,
        paginationRowsPerPageOptions: [ROWS_PER_PAGE],
        paginationPerPage: ROWS_PER_PAGE
      }
    : {};

  const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: !serverSidePagination,
    selectAllRowsItemText: 'Todos'
  };

  const applyServerSideFilter = useMemo(
    () => (formFields: { [key: string]: any }) => {
      // set fields
      setFormFilters(formFields);
      if (sharedFilterName) {
        addObjectToStorage(sharedFilterName, formFields);
      }
    },
    [sharedFilterName]
  );

  const onRowClicked: (row: BasicEntity, e: React.MouseEvent) => void = (row, e) => {
    if (selectedSingleEntity) {
      selectedSingleEntity(row, e);
    }
    setIdEntity(row._id);
  };

  const RowStyles = () => {
    const conditionalRowStyles = [
      {
        when: (row: any) => row._id === idEntity,
        style: {
          backgroundColor: '#F7FBF3',
          border: '2px solid var(--Gray-4, #BDBDBD)'
        }
      }
    ];
    return conditionalRowStyles;
  };

  return (
    <div style={{ display: 'flex ', flexDirection: 'column', gap: 12 }}>
      {tableName && <h2 className='title-DataTable'>{tableName.toLocaleUpperCase()}</h2>}
      {renderActions()}
      <DataTable<T & BasicEntity>
        columns={tableColumns}
        data={filteredItems}
        title=' ' // The title is needed for a header to exist
        noHeader={!(selectedRows.length > 0)} // when there are selected rows, the header is displayed, but the selection bar covers the title “ ”
        customStyles={customStyles}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        progressPending={loading}
        subHeader={!serverSidePagination && ViewInput ? true : false}
        subHeaderComponent={!serverSidePagination ? subHeaderComponentMemo : null}
        selectableRows={canSelect}
        pointerOnHover={singleSelect}
        onRowClicked={onRowClicked}
        selectableRowDisabled={rowDisabledCriteria}
        persistTableHead
        contextActions={contextActions}
        onSelectedRowsChange={onSelectedRowsChange && handleRowSelected}
        clearSelectedRows={toggleCleared}
        responsive
        selectableRowsVisibleOnly={selectablePageOnly}
        paginationComponentOptions={paginationOptions}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        paginationComponent={
          serverSidePagination
            ? (props) => (
                <Pagination
                  rowCount={props.rowCount}
                  currentPage={props.currentPage}
                  currentRowsPerPage={pageSize}
                  availablePages={pagination ? pagination.availablePages : 0}
                  onChangePage={props.onChangePage}
                  onChangeRowsPerPage={props.onChangeRowsPerPage}
                />
              )
            : undefined
        }
        noDataComponent={<EmptyTableMessage />}
        sortFunction={additionalTableProps?.sortFunction}
        striped={additionalTableProps?.striped || true}
        conditionalRowStyles={additionalTableProps?.conditionalRowStyles || RowStyles()}
        paginationTotalRows={serverSidePaginationOptions?.paginationTotalRows}
        paginationServer={serverSidePaginationOptions?.paginationServer}
        onChangePage={serverSidePaginationOptions?.onChangePage}
        onChangeRowsPerPage={serverSidePaginationOptions?.onChangeRowsPerPage}
        paginationRowsPerPageOptions={serverSidePaginationOptions?.paginationRowsPerPageOptions}
        paginationPerPage={serverSidePaginationOptions?.paginationPerPage}
      />
    </div>
  );
};

export default GenericTable;
