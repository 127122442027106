import React, { FunctionComponent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { DashboardCRUD } from '../new-table/components/GeneratedComponents';
import BackButton from '../new-table/components/BackButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { EstadoOCA, TipoCobro } from '../types';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import { formatStringNumber } from '../utils/utils';
import { estadoColors } from '../new-table/utils';

const TrazabilidadDashboard: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<TipoCobro>(TipoCobro.merma);
  const handleChange = (_: SyntheticEvent, newValue: TipoCobro) => {
    setValue(newValue);
  };

  const CRUDmerma = useMemo(() => DashboardCRUD(TipoCobro.merma), []);
  const CRUDfaltante = useMemo(() => DashboardCRUD(TipoCobro.faltante), []);

  const [total, setTotal] = useState<{
    [key: string]: {
      label: EstadoOCA;
      total: number;
      cantidad: number;
      pocentaje: number;
    };
  }>({});

  useEffect(() => {
    setLoading(entities.cobroDashboard.loading);
  }, [entities.cobroDashboard.loading]);

  useEffect(() => {
    const estados = Object.values(EstadoOCA);

    const total = entities.cobroDashboard.list.reduce((suma, obj) => {
      if (!isNaN(Number(obj.precio)) && !isNaN(Number(obj.cantidadCajas))) {
        return suma + Number(obj.precio) * Number(obj.cantidadCajas);
      }
      return suma;
    }, 0);

    const nuevosTotales = estados.reduce((acc, estado) => {
      const filtroEstado = entities.cobroDashboard.list.filter((obj) => obj.estadoOca === estado);

      const totalEstado = filtroEstado.reduce((suma, obj) => {
        if (!isNaN(Number(obj.precio)) && !isNaN(Number(obj.cantidadCajas))) {
          return suma + Number(obj.precio) * Number(obj.cantidadCajas);
        }
        return suma;
      }, 0);

      const cantidad = filtroEstado.length;

      acc[estado] = {
        label: estado,
        total: totalEstado,
        cantidad,
        pocentaje: total > 0 ? (totalEstado / total) * 100 : 0
      };

      return acc;
    }, {} as { [key: string]: { label: EstadoOCA; total: number; cantidad: number; pocentaje: number } });

    setTotal(nuevosTotales);
  }, [entities.cobroDashboard]);

  return (
    <div className='trazabilidad-dashboard-container'>
      <div>
        <BackButton route='/cobros' />
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor='inherit'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#64a70b'
              }
            }}>
            <Tab label='Merma' value={TipoCobro.merma} />
            <Tab label='Faltante' value={TipoCobro.faltante} />
          </TabList>
        </Box>
        <TabPanel value={TipoCobro.merma}>
          <div className='trazabilidad-dashboard-card-container'>
            {!loading &&
              Object.keys(total).map((key, idx) => (
                <div
                  key={idx}
                  className='trazabilidad-dashboard-card'
                  style={{ borderColor: estadoColors[total[key].label] }}>
                  <p>
                    ({formatStringNumber(total[key].cantidad.toString())}) {total[key].label}
                  </p>
                  <h2>${formatStringNumber(total[key].total.toString())}</h2>
                  <p>{formatStringNumber(total[key].pocentaje.toString(), 0, 2)} %</p>
                </div>
              ))}
          </div>
          <CRUDmerma />
        </TabPanel>
        <TabPanel value={TipoCobro.faltante}>
          <div className='trazabilidad-dashboard-card-container'>
            {!loading &&
              Object.keys(total).map((key, idx) => (
                <div
                  key={idx}
                  className='trazabilidad-dashboard-card'
                  style={{ borderColor: estadoColors[total[key].label] }}>
                  <p>
                    ({formatStringNumber(total[key].cantidad.toString())}) {total[key].label}
                  </p>
                  <h2>${formatStringNumber(total[key].total.toString())}</h2>
                  <p>{formatStringNumber(total[key].pocentaje.toString(), 0, 2)} %</p>
                </div>
              ))}
          </div>
          <CRUDfaltante />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default connect(({ entities }: AppState) => ({ entities }), null)(TrazabilidadDashboard);
