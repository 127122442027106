import React, { CSSProperties, Fragment, FunctionComponent, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import GenericIcons from '../../components/generics/GenericIcons';

const ModalComponent: FunctionComponent<{
  modalTitle: string;
  body: JSX.Element;
  actionBtn: JSX.Element;
  btnLabel: string;
  btnIcon?: JSX.Element;
  btnVariant?: 'text' | 'outlined' | 'contained';
  btnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  customStyles?: {
    modalBtnContainer?: CSSProperties;
    modalBtn?: CSSProperties;
    modalContainer?: CSSProperties;
    modalContent?: CSSProperties;
    closeContainer?: CSSProperties;
    bodyContainer?: CSSProperties;
    title?: CSSProperties;
    actionBtn?: CSSProperties;
  };
  disabledModalBtn?: boolean;
  toggleModal?: (isOpen: boolean) => void;
  isModalOpen?: boolean;
}> = ({
  modalTitle,
  body,
  btnLabel,
  btnIcon,
  actionBtn,
  btnVariant,
  btnColor,
  customStyles,
  disabledModalBtn,
  toggleModal,
  isModalOpen
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
    if (toggleModal) {
      toggleModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (toggleModal) {
      toggleModal(false);
    }
  };

  useEffect(() => {
    if (isModalOpen !== undefined) {
      setOpen(isModalOpen);
    }
  }, [isModalOpen]);

  return (
    <Fragment>
      <div style={customStyles?.modalBtnContainer}>
        <Button
          disabled={disabledModalBtn}
          onClick={handleOpen}
          variant={btnVariant}
          color={btnColor}
          style={customStyles?.modalBtn}>
          {btnIcon}
          {btnLabel}
        </Button>
      </div>

      <Modal open={open} onClose={handleClose} sx={{ zIndex: 999 }}>
        <div style={{ ...Styles.modalContainer, ...customStyles?.modalContainer }}>
          <div style={{ ...Styles.modalContent, ...customStyles?.modalContent }}>
            <div style={{ ...Styles.closeContainer, ...customStyles?.closeContainer }}>
              <h2 style={{ ...Styles.title, ...customStyles?.title }}>{modalTitle}</h2>
              <GenericIcons
                icon='closeIcon'
                imageStyle={{ height: '15px', cursor: 'pointer' }}
                onClick={() => handleClose()}
              />
            </div>

            <div style={{ ...Styles.bodyContainer, ...customStyles?.bodyContainer }}>{body}</div>

            <div style={{ ...Styles.actionBtn, ...customStyles?.actionBtn }}>{actionBtn}</div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;

const Styles: { [key: string]: CSSProperties } = {
  modalContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: '#f3f3f3',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: '80%',
    height: '90%',
    padding: '20px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalContent: {
    flex: '1',
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px'
  },
  closeContainer: {
    width: '100%',
    height: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bodyContainer: {
    height: '87%',
    width: '100%',
    overflow: 'auto',
    borderRadius: '5px',
    border: '2px solid #e0e0e0',
    background: '#fff',
    boxShadow: '4px 5px 7px 0px #d5d6d7'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333',
    textTransform: 'uppercase',
    margin: 0
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
};
